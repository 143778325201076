import { createApi } from '@reduxjs/toolkit/query/react';

import { removeFalsyValuesFromObject } from 'Util/utilities';
import { onQueryStartedErrorToast } from 'Util/rtkQueryErrorHandler';
import { objectToQueryString } from '../../../Util/utility';

import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const COMMON_BATCH_URL = 'api/v1/internal-service/batch';

const GET_BATCH_URL = `${COMMON_BATCH_URL}/get`;
const GET_BATCH_CAREER_FIELD = `${COMMON_BATCH_URL}/career-fields`;
const GET_BATCH_INTERNSHIP_LOCATION = `${COMMON_BATCH_URL}/internship-locations`;
const GET_BATCH_INTERNSHIP_DURATION = `${COMMON_BATCH_URL}/internship/durations`;

export const ManageBatchApi = createApi({
  reducerPath: 'manageBatchApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getBatchInfo: builder.query({
      query: ({ batchId }) => {
        const updatedUrl = `${GET_BATCH_URL}/${batchId}`;
        return { url: updatedUrl, method: 'GET' };
      },
      onQueryStarted: onQueryStartedErrorToast,
    }),

    getBatchCareerFields: builder.query({
      query: ({ ...filters }) => {
        const queryString = filters
          ? objectToQueryString(removeFalsyValuesFromObject(filters))
          : '';
        const updatedUrl = queryString
          ? `${GET_BATCH_CAREER_FIELD}?${queryString}`
          : GET_BATCH_CAREER_FIELD;
        return { url: updatedUrl, method: 'GET' };
      },
      onQueryStarted: onQueryStartedErrorToast,
    }),

    getBatchLocations: builder.query({
      query: ({ ...filters }) => {
        const queryString = filters
          ? objectToQueryString(removeFalsyValuesFromObject(filters))
          : '';
        const updatedUrl = queryString
          ? `${GET_BATCH_INTERNSHIP_LOCATION}?${queryString}`
          : GET_BATCH_INTERNSHIP_LOCATION;
        return { url: updatedUrl, method: 'GET' };
      },
      onQueryStarted: onQueryStartedErrorToast,
    }),

    getBatchDurations: builder.query({
      query: ({ ...filters }) => {
        const queryString = filters
          ? objectToQueryString(removeFalsyValuesFromObject(filters))
          : '';
        const updatedUrl = queryString
          ? `${GET_BATCH_INTERNSHIP_DURATION}?${queryString}`
          : GET_BATCH_INTERNSHIP_DURATION;
        return { url: updatedUrl, method: 'GET' };
      },
      onQueryStarted: onQueryStartedErrorToast,
    }),
  }),
});

export const {
  useGetBatchInfoQuery,
  useGetBatchCareerFieldsQuery,
  useGetBatchDurationsQuery,
  useGetBatchLocationsQuery,
} = ManageBatchApi;
export default ManageBatchApi.reducer;
