import './wdyr';
import React from 'react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { datadogLogs } from '@datadog/browser-logs';

import { Provider } from 'react-redux';
import { reduxStore } from './Redux/Store';
import { jwtRequestInterceptor, jwtResponseInterceptor } from './Axios';
import * as Sentry from '@sentry/react';
import { TimeZoneContextProvider } from './Util/timezoneContext';
import { GoogleOAuthProvider } from '@react-oauth/google';

/* Axios interceptors for request and response to handle jwt token handling*/
jwtRequestInterceptor();
jwtResponseInterceptor();

const container = document.getElementById('root');
const root = createRoot(container);

const ignoredSources = ['mixpanel', 'google analytics', 'clarity', 'flush'];

if (process.env.REACT_APP_CLUSTER === 'prod') {
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    forwardErrorsToLogs: true,
    forwardReports: 'all',
    sessionSampleRate: 100,
    env: `${process.env.REACT_APP_CLUSTER}:moo-app`,
    service: 'fe-moo-app',
    version: process.env.REACT_APP_VERSION,
    beforeSend: (log) => {
      const logMessage = log?.message?.toLowerCase();
      const logStack = log?.error?.stack?.toLowerCase() || '';

      const shouldIgnore = ignoredSources.some(
        (source) => logMessage.includes(source) || logStack.includes(source),
      );

      return !shouldIgnore;
    },
  });
}

if (process.env.REACT_APP_CLUSTER !== 'local') {
  Sentry.init({
    dsn: 'https://a10a78ec21024084aaa672b8774347f4@o4504966076104704.ingest.sentry.io/4505005647331328',
    integrations: [new Sentry.browserTracingIntegration()],
    environment: process.env.REACT_APP_CLUSTER,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    ignoreErrors: [
      'Non-Error promise rejection captured',
      'Non-Error exception captured',
      /Loading chunk [/d]+ failed/i,
      'ChunkLoadError',
      /Loading chunk [\d]+ failed/,
      /Loading CSS chunk [\d]+ failed/,
      /Loading CSS chunk [/d]+ failed/i,
      "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.", // eslint-disable-line
      "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.", // eslint-disable-line
    ],
    beforeSend: (event, hint) => {
      const error = hint?.originalException;
      if (
        error &&
        typeof error !== 'string' &&
        error?.message &&
        (error?.message?.includes('chunk') ||
          error?.message?.includes('Chunk') ||
          error?.message?.includes('Unexpected token'))
      )
        return null;

      return event;
    },
  });
}

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_MOO_GOOGLE_CLIENT_ID}>
      <Provider store={reduxStore}>
        <TimeZoneContextProvider>
          <App />
        </TimeZoneContextProvider>
      </Provider>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
