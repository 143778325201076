import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';
import { createMassSystemLogs } from '../Common/CreateMassSystemLogs';
import { getIEMList } from './GetIEMList';

const assignIEMBulkURL = '/api/v1/internal-service/intern/bulk-add-iem';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const assignIEMBulk = createAsyncThunk(
  'assignIEMBulk',
  async (request, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    try {
      const {
        InternList: { response },
      } = getState();

      // Get the IEM Details
      const iemDetails = await getIEMList(true);

      // To Find the intern detail by using ID
      const findInternDetailsById = (id) => {
        return response?.find((item) => item?.intern_id === id);
      };

      // To Find the iem detail by using ID
      const findIemDetailsById = (id) => {
        return iemDetails?.response?.find((item) => item?.value === id);
      };

      // create mass system logs for Assigning IEM to intern
      const internsInfo = request?.intern_ids
        ?.map((id) => {
          const internPreviousIemDetails = findInternDetailsById(id);
          const logObj = {
            feature_type_id: id,
            feature_type: 'INTERN',
            action: 'Update',
            column_name: 'Assigned IEM',
            from_value:
              internPreviousIemDetails?.iem !== null
                ? internPreviousIemDetails?.iem?.full_name
                : '  ',
            to_value: findIemDetailsById(request?.iem_id)?.label,
          };
          if (logObj?.from_value !== logObj?.to_value) {
            return logObj;
          }
        })
        ?.filter((item) => item !== undefined);
      const result = await axios.post(assignIEMBulkURL, request);
      if (result?.data?.data?.isSuccess && internsInfo.length > 0) {
        dispatch(createMassSystemLogs(internsInfo));
      }
      return fulfillWithValue(result.data);
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);

const AssignIEMBulkSlice = createSlice({
  name: 'AssignIEMBulk',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate assignIEMBulk state handling*/
      .addCase(assignIEMBulk.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(assignIEMBulk.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.data;
      })
      .addCase(assignIEMBulk.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getAssignIEMBulkState = (state) => state.AssignIEMBulk;
export default AssignIEMBulkSlice.reducer;
