import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';
import { configs } from '../../../Util/configs';

const BASE_URL = configs.REACT_APP_BASE_URL_OTHR;
const CreateSystemLogsURL = BASE_URL + '/common-services/systemlog/create';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const createSystemLogs = createAsyncThunk(
  'createSystemLogs',
  async (request, { rejectWithValue, fulfillWithValue, getState }) => {
    try {
      const {
        UserProfile: {
          response: { payload },
        },
      } = getState();
      const changeLogData = {
        employee_id: payload?.employee_id,
        employees_name: payload?.full_name,
        ...request,
      };
      const result = await axios.post(CreateSystemLogsURL, changeLogData);
      return fulfillWithValue(result.data?.data);
    } catch (error) {
      throw rejectWithValue(error?.message);
    }
  },
);

const CreateSystemLogsSlice = createSlice({
  name: 'CreateSystemLogs',
  initialState,
  extraReducers: {
    [createSystemLogs.pending]: (state) => {
      state.status = 'Pending';
      state.error = null;
    },
    [createSystemLogs.fulfilled]: (state, action) => {
      state.status = 'Success';
      state.response = action?.payload;
    },
    [createSystemLogs.rejected]: (state, action) => {
      state.status = 'Failed';
      state.error = action?.payload;
    },
  },
});

export const getCreateSystemLogsState = (state) => state.CreateSystemLogs;
export default CreateSystemLogsSlice.reducer;
