import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const updateInternProfileFeedbackURL =
  '/api/v1/internal-service/intern-application/intern-profile-feedback';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
  isLoading: false,
};

export const updateInternProfileFeedback = createAsyncThunk(
  'updateInternProfileFeedback',
  async (request, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await axios.post(updateInternProfileFeedbackURL, request);
      if (result?.data?.data?.isSuccess) {
        return fulfillWithValue(result?.data?.data);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const updateInternProfileFeedbackSlice = createSlice({
  name: 'updateInternProfileFeedback',
  initialState,
  reducers: {
    updateInternProfileFeedbackStateReset: (state) => {
      state.status = 'idle';
      state.error = null;
      state.response = null;
      state.isLoading = false;
    },
  },
  extraReducers: {
    [updateInternProfileFeedback.pending]: (state) => {
      state.status = 'Pending';
      state.isLoading = true;
    },
    [updateInternProfileFeedback.fulfilled]: (state, action) => {
      state.status = 'Success';
      state.response = action?.payload;
      state.isLoading = false;
    },
    [updateInternProfileFeedback.rejected]: (state, error) => {
      state.status = 'Failed';
      state.error = error;
      state.isLoading = false;
    },
  },
});

export const updateInternProfileFeedbackState = (state) => state.UpdateInternProfileFeedback;
export const { updateInternProfileFeedbackStateReset } = updateInternProfileFeedbackSlice.actions;
export default updateInternProfileFeedbackSlice.reducer;
