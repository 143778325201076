import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

import { objectToQueryString } from '../../../Util/utility';

const url = '/api/v1/internal-service/host-company/interview/list';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const getInterviewList = createAsyncThunk(
  'getInterviewList',
  async (request, { rejectWithValue }) => {
    const queryString = objectToQueryString(request);
    const updatedUrl = queryString ? `${url}?${queryString}` : url;
    const result = await axios
      .get(updatedUrl)
      .then(({ data: { data: response } }) => {
        return response;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetInterviewList = createSlice({
  name: 'GetInterviewList',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getInterviewList.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getInterviewList.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload;
      })
      .addCase(getInterviewList.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getInterviewListState = (state) => state.GetInterviewList;
export default GetInterviewList.reducer;
