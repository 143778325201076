import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

export const GetCandidatureInfoApi = createApi({
  reducerPath: 'GetCandidatureInfoApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    interviewOffer: builder.query({
      query: (request) => ({
        url: `/api/v1/internal-service/pam/application/${request.application_id}/interview-offer`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useInterviewOfferQuery } = GetCandidatureInfoApi;
export default GetCandidatureInfoApi.reducer;
