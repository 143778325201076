import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const GetInternshipOpportunityDetailURL = '/api/v1/internal-service/io/get-internship-opportunity-detail/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const getInternshipOpportunityDetail = createAsyncThunk(
  'getInternshipOpportunityDetail',
  async (ioID, { rejectWithValue }) => {
    const result = await axios
      .get(GetInternshipOpportunityDetailURL + ioID)
      .then((response) => {
        return response?.data?.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetInternshipOpportunityDetail = createSlice({
  name: 'GetInternshipOpportunityDetail',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getInternshipOpportunityDetail.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getInternshipOpportunityDetail.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload;
      })
      .addCase(getInternshipOpportunityDetail.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getInternshipOpportunityDetailState = (state) => state.GetInternshipOpportunityDetail;
export default GetInternshipOpportunityDetail.reducer;
