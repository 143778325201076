import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const getCompanyDetails = '/api/v1/internal-service/io/company';
const getCompanyPocDetails = '/api/v1/internal-service/io/company/poc';
const getJobFunctionsUrl = '/common-services/job-functions';
const getSkillsUrl = '/common-services/career-skill/list';
const createIoUrl = '/api/v1/internal-service/io';
const updateIoUrl = '/api/v1/internal-service/io';
const updateIoStatusUrl = '/api/v1/internal-service/io/update-io-status';
const getInternshipByIdUrl = '/api/v1/internal-service/io/get-internship-opportunity-detail';
const getCareerFieldsUrl = '/common-services/career-field/get-list';

export const InternshipOpportunityApi = createApi({
  reducerPath: 'internshipOpportunity',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['internshipRequirementById'],
  endpoints: (builder) => ({
    getSkills: builder.query({
      query: () => {
        return { url: getSkillsUrl, method: 'GET' };
      },
    }),

    getInternshipById: builder.query({
      query: (id) => {
        return { url: `${getInternshipByIdUrl}/${id}`, method: 'GET' };
      },
      providesTags: ['internshipRequirementById'],
    }),
    getJobFunctions: builder.query({
      query: (body) => {
        let queryString = Object.entries(body)
          .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
          .join('&');

        let updatedUrl = queryString ? `${getJobFunctionsUrl}?${queryString}` : getJobFunctionsUrl;
        return { url: updatedUrl, method: 'GET' };
      },
    }),
    createInternshipOpportunity: builder.mutation({
      query: (body) => ({ url: createIoUrl, method: 'POST', body }),
    }),
    getHostCompanyDetails: builder.mutation({
      query: (body) => ({ url: getCompanyDetails, method: 'POST', body }),
    }),
    getHostCompanyPocDetails: builder.mutation({
      query: (body) => ({ url: getCompanyPocDetails, method: 'POST', body }),
    }),
    updateInternshipOpportunityStatus: builder.mutation({
      query: (body) => ({
        url: updateIoStatusUrl,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['internshipRequirementById'],
    }),

    updateInternshipOpportunity: builder.mutation({
      query: ({ ioId, payload }) => {
        return {
          url: `${updateIoUrl}/${ioId}`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: ['internshipRequirementById'],
    }),
    getCareerFields: builder.query({
      query: () => {
        return { url: getCareerFieldsUrl, method: 'GET' };
      },
    }),
  }),
});

export const {
  useGetHostCompanyPocDetailsMutation,
  useGetHostCompanyDetailsMutation,
  useGetJobFunctionsQuery,
  useGetSkillsQuery,
  useCreateInternshipOpportunityMutation,
  useUpdateInternshipOpportunityStatusMutation,
  useUpdateInternshipOpportunityMutation,
  useGetInternshipByIdQuery,
  useGetCareerFieldsQuery,
} = InternshipOpportunityApi;
export default InternshipOpportunityApi.reducer;
