import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const checkEmailAvailabilityURL = '/api/v1/internal-service/host-company/validate/email?email=';
const createCompanyURL = '/api/v1/internal-service/host-company/create';

export const CreateCompanyApi = createApi({
  reducerPath: 'CreateCompanyApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    checkEmailAvailability: builder.query({
      query: (emailID) => ({ url: checkEmailAvailabilityURL + emailID, method: 'GET' }),
    }),
    createCompany: builder.mutation({
      query: (data) => {
        return {
          url: createCompanyURL,
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const { useLazyCheckEmailAvailabilityQuery, useCreateCompanyMutation } = CreateCompanyApi;
export default CreateCompanyApi.reducer;
