import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const getInternVideosURL = '/api/v1/internal-service/intern-application/question-video';
const getInternProfileCompletionURL =
  '/api/v1/internal-service/intern-application/intern-profile-completion/';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
  isLoading: false,
  profilePercentage: 0,
};

export const getInternVideos = createAsyncThunk(
  'getInternVideos',
  async (request, { rejectWithValue }) => {
    try {
      const [videosResult, profileResult] = await Promise.all([
        axios.post(getInternVideosURL, request),
        axios.get(getInternProfileCompletionURL + request.intern_id),
      ]);
      if (videosResult.data.status !== 200 || profileResult.data.status !== 200) {
        throw new Error('Response status not 200');
      }
      return {
        videos: videosResult.data.data,
        completionPercentage: profileResult.data.data,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getInternVideosSlice = createSlice({
  name: 'getInternVideos',
  initialState,
  extraReducers: {
    [getInternVideos.pending]: (state) => {
      state.status = 'Pending';
      state.isLoading = true;
    },
    [getInternVideos.fulfilled]: (state, action) => {
      state.status = 'Success';
      state.response = action?.payload?.videos?.payload;
      state.profilePercentage = action?.payload?.completionPercentage?.payload;
      state.isLoading = false;
    },
    [getInternVideos.rejected]: (state, error) => {
      state.status = 'Failed';
      state.error = error;
      state.isLoading = false;
    },
  },
});

export const getInternVideosState = (state) => state.GetInternVideos;
export default getInternVideosSlice.reducer;
