import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';
import { createSystemLogs } from '../Common/CreateSystemLogs';

const createNotesURL = '/api/v1/internal-service/note/create-note';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

/** @request
 {
    "intern_id":4,
    "author_employee_id":4,
    "note_content":"skow"
}
 */

export const createNotes = createAsyncThunk(
  'createNotes',
  async (request, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const result = await axios.post(createNotesURL, request);
      // creating notes object
      const notesInfo = {
        feature_type_id: request?.intern_id,
        feature_type: 'INTERN',
        action: 'Create',
        column_name: 'Intern Notes',
        from_value: ' ',
        to_value: request?.note_content,
      };
      if (result?.data?.data?.isSuccess) {
        dispatch(createSystemLogs(notesInfo));
      }
      return fulfillWithValue(result?.data?.data);
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);

const CreateNotesSlice = createSlice({
  name: 'CreateNotes',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate createNotes state handling*/
      .addCase(createNotes.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(createNotes.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.payload;
      })
      .addCase(createNotes.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getCreateNotesState = (state) => state.CreateNotes;
export default CreateNotesSlice.reducer;
