import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const GetBasicDetailsURL = '/api/v1/internal-service/host-company/get-basic-details';

export const GetBasicDetails = createApi({
  reducerPath: 'getBasicDetails',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getBasicDetails: builder.query({
      query: (request) => ({ url: GetBasicDetailsURL, method: 'POST', body: request }),
    }),
  }),
});

export const { useGetBasicDetailsQuery } = GetBasicDetails;
