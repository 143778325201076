import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const getWeeklyInternDetailsURL = '/api/v1/internal-service/weekly-feedback-loop/internships/';

export const GetWeeklyInternDetailsApi = createApi({
  reducerPath: 'getWeeklyInternDetailsApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getWeeklyInternDetailsApi: builder.query({
      query: (data) => ({ url: getWeeklyInternDetailsURL + data?.internship_id, method: 'GET' }),
    }),
  }),
});

export const { useGetWeeklyInternDetailsApiQuery } = GetWeeklyInternDetailsApi;

export default GetWeeklyInternDetailsApi.reducer;
