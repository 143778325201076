import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const BatchNotesURL = '/api/v1/internal-service/batch';

export const GetCountByStartDateApi = createApi({
  reducerPath: 'GetCountByStartDate',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getCountByStartDate: builder.query({
      query: ({ batchId }) => {
        const url = `${BatchNotesURL}/${batchId}/count-by-start-date`;
        return { url, method: 'GET', body: null };
      },
    }),
  }),
});

export const { useGetCountByStartDateQuery } = GetCountByStartDateApi;
export default GetCountByStartDateApi.reducer;
