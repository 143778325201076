import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const url = '/api/v1/internal-service/batch/list';

export const ManageBatchList = createApi({
  reducerPath: 'ManageBatchList',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getBatchList: builder.query({
      query: (request = {}) => {
        return {
          url,
          method: 'POST',
          body: request,
        };
      },
    }),
  }),
});

export const { useGetBatchListQuery, usePrefetch } = ManageBatchList;
export default ManageBatchList.reducer;
