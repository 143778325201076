import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosInstance';
import { objectToQueryString } from '../../../Util/utility';

const url = '/api/v1/internal-service/offers/list';

export const GetOfferListApi = createApi({
  reducerPath: 'GetOfferListApi',
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getOfferList: builder.query({
      query: (request) => {
        const queryString = objectToQueryString(request);
        const updatedUrl = queryString ? `${url}?${queryString}` : url;
        return { url: updatedUrl, method: 'GET' };
      },
    }),
  }),
});

export const { useGetOfferListQuery, usePrefetch } = GetOfferListApi;

export default GetOfferListApi.reducer;
