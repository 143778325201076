import React, { useState, createContext, useEffect } from 'react';
import { getTimeZones } from '@vvo/tzdb';

export const TimeZoneContext = createContext(null);

export function TimeZoneContextProvider({ children }) {
  const [timeZoneList, setTimeZoneList] = useState(false);

  useEffect(() => {
    const timeZonesWithUtc = getTimeZones({ includeUtc: true });
    const sortedTimeZones = [...timeZonesWithUtc].sort((a, b) => {
      return a?.name?.localeCompare(b?.name);
    });
    const result = [];
    sortedTimeZones.forEach((item) => {
      const offsetPattern = /[+-]\d{2}:\d{2}/; // Matches a pattern like +00:00
      const match = item?.currentTimeFormat?.match(offsetPattern);
      let offset = '';
      if (match) {
        offset = match[0]; // Extract the matched offset
      } else {
        console.log('');
      }
      result.push({
        label: item?.countryName
          ? `${item?.name}, ${item?.countryName} - ${item?.alternativeName}, (GMT${offset}) `
          : item?.name,

        value: item?.name,
        entity: item,
        offset,
        abbreviation: item?.abbreviation,
      });
    });
    setTimeZoneList(result);
  }, []);

  return <TimeZoneContext.Provider value={timeZoneList}>{children}</TimeZoneContext.Provider>;
}
