import React from 'react';
import SnackbarAlert from './Snackbar';

const GlobalPopups = () => {
  return (
    <div>
      <SnackbarAlert />
    </div>
  );
};

export default GlobalPopups;
