import { createSlice } from '@reduxjs/toolkit';

const ManageTagInfo = createSlice({
  name: 'info',
  initialState: [],
  reducers: {
    addInfo: (state, action) => {
      state.push(action.payload);
    },
    removeInfo: (state, action) => {
      return state.filter((info) => {
        if (info.id === action.payload.id && info.tag_type === action.payload.tag_type) {
          return false;
        } else return true;
      });
    },
    resetInfo: () => {
      return [];
    },
  },
});

export const { addInfo, resetInfo, removeInfo } = ManageTagInfo.actions;
export default ManageTagInfo.reducer;
