import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';

import { NavSectionVertical } from './NavSection/vertical/NavSectionVertical';
import { NavSectionMini } from './NavSection/mini/NavSectionMini';

import { getCdnUrl } from '../../Util/utility';
import { slotProps, slotPropsMini } from './constants';

import './Navbar.scss';

const NavBar = ({ navData, navLayout, setNavLayout }) => {
  const isNavMini = navLayout === 'mini';

  const onToggleNav = () =>
    setNavLayout((navLayout) => (navLayout === 'vertical' ? 'mini' : 'vertical'));

  const renderNavVertical = (
    <>
      <Box className='logoBox'>
        <img
          src={
            process.env.REACT_APP_CLUSTER === 'uat'
              ? getCdnUrl('/Images/Global/vi-logo-uat.png')
              : getCdnUrl('/Images/Global/viLogo.svg')
          }
          width={144}
        />
      </Box>

      <Box
        component={({ children }) => (
          <div style={{ height: 'calc(100% - 72px)', overflowY: 'auto' }}>{children}</div>
        )}
        clickOnTrack={false}
        className='scrollbar'
      >
        <NavSectionVertical data={navData} slotProps={slotProps} />
      </Box>
    </>
  );

  const renderNavMini = (
    <>
      <Box className='miniLogoBox'>
        <img src={getCdnUrl('/Images/Global/logoIcon.svg')} />
      </Box>

      <NavSectionMini data={navData} slotProps={slotPropsMini} />
    </>
  );

  return (
    <Box className={`navBar slide-animation ${isNavMini ? 'navBarMini' : 'navBarVertical'}`}>
      <IconButton size='small' className='iconButton navToggleButton' onClick={onToggleNav}>
        <SvgIcon className={`svgIcon ${isNavMini ? 'svgIconMini' : ''}`}>
          <path
            fill='currentColor'
            d='M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64'
          />
        </SvgIcon>
      </IconButton>

      {isNavMini ? renderNavMini : renderNavVertical}
    </Box>
  );
};

export default NavBar;
