import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';
import { createSystemLogs } from '../Common/CreateSystemLogs';

const deleteCompanyNotesURL = '/api/v1/internal-service/company-note/delete-note/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};



export const deleteCompanyNotes = createAsyncThunk(
  'deleteCompanyNotes',
  async (request, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      // creating notes object
      const notesInfo = {
        feature_type_id: request?.host_company_id,
        feature_type: 'COMPANY',
        action: 'Delete',
        column_name: 'Host Company Notes',
        from_value: ' ',
        to_value: request?.note_content,
      };

      // removing host_company_id and note_content property from request object
      delete request['host_company_id'];
      delete request['note_content'];
      const result = await axios.delete(deleteCompanyNotesURL + request['note_id']);

      if (result?.data?.data?.isSuccess) {
        dispatch(createSystemLogs(notesInfo));
      }
      return fulfillWithValue(result?.data?.data);
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);

const DeleteCompanyNotesSlice = createSlice({
  name: 'DeleteCompanyNotes',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate deleteCompanyNotes state handling*/
      .addCase(deleteCompanyNotes.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(deleteCompanyNotes.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.payload;
      })
      .addCase(deleteCompanyNotes.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getDeleteCompanyNotesState = (state) => state.DeleteCompanyNotes;
export default DeleteCompanyNotesSlice.reducer;
