import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const checkIfInternCanRecommendURL =
  '/api/v1/internal-service/recommendation/check';

export const CheckIfInternCanRecommendApi = createApi({
  reducerPath: 'CheckIfInternCanRecommendApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    checkIfInternCanRecommend: builder.mutation({
      query: (data) => ({ url: checkIfInternCanRecommendURL, method: 'POST' , body : data}),
    }),
  }),
});

export const { useCheckIfInternCanRecommendMutation } = CheckIfInternCanRecommendApi;
export default CheckIfInternCanRecommendApi.reducer;