import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const url = '/api/v1/internal-service/offers/get-feedback-detail';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const getFeedback = createAsyncThunk('getFeedback', async (request, { rejectWithValue }) => {
  const result = await axios
    .get(url + `/${request}`)
    .then((response) => {
      return response?.data?.data?.payload;
    })
    .catch((error) => {
      throw rejectWithValue(error);
    });
  return result;
});

const GetFeedback = createSlice({
  name: 'GetFeedback',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getFeedback.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getFeedback.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload;
      })
      .addCase(getFeedback.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getFeedbackState = (state) => state.GetFeedback;
export default GetFeedback.reducer;
