import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const getPermissionsURL = '/api/v1/internal-service/subpage/list';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
let formatedPayload;

export const getPermissions = createAsyncThunk(
  'getPermissions',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .get(getPermissionsURL)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log('getPermissions-Catch', request);
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetPermissionslice = createSlice({
  name: 'GetPermissions',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate get all user list state handling*/
      .addCase(getPermissions.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getPermissions.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.data;
      })
      .addCase(getPermissions.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const formatPermissionPayloadUtil = (payload) => {
  const rawResult = [];
  const groupedArray = [];

  payload?.map((rootitem) => {
    const pages = {
      parent: '',
      sub_page_list: [],
    };
    const permissions = [];
    pages.parent = rootitem.parent;
    rootitem.permissions?.map((item) => {
      permissions.push({
        internal_permission_id: item.internal_permission_id,
        permission_action: item.permission_action,
      });
    });
    pages.sub_page_list.push({
      sub_page_name: rootitem.sub_page_name,
      sub_page_id: rootitem.sub_page_id,
      permission: permissions,
    });
    rawResult.push(pages);
  });

  rawResult.forEach((item) => {
    const found = groupedArray.find((x) => x.parent === item.parent);
    if (found) {
      found.sub_page_list.push(item.sub_page_list[0]);
    } else {
      groupedArray.push({
        parent: item.parent,
        sub_page_list: [item.sub_page_list[0]],
      });
    }
  });
  formatedPayload = groupedArray;
  return formatedPayload;
};

export const getPageInfoUtil = (internalPermissionId, payload) => {
  let pageInfo = {
    parent: '',
    subPageName: '',
    permissionAction: '',
  };
  payload?.map((rootItem) => {
    rootItem.permissions?.map((item) => {
      if (item.internal_permission_id === internalPermissionId) {
        pageInfo.parent = rootItem.parent;
        pageInfo.subPageName = rootItem.sub_page_name;
        pageInfo.permissionAction = item.permission_action;
      }
    });
  });
  return pageInfo;
};

export const getPermissionsState = (state) => state.Permissions;
export default GetPermissionslice.reducer;
