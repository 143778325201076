import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const updateWeeklyQuestionURL =
  '/api/v1/internal-service/weekly-feedback-loop/edit-feedback-question';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const updateWeeklyQuestion = createAsyncThunk(
  'updateWeeklyQuestion',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(updateWeeklyQuestionURL, request)
      .then((response) => {
        return response?.data?.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const UpdateWeeklyQuestion = createSlice({
  name: 'UpdateWeeklyQuestion',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(updateWeeklyQuestion.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(updateWeeklyQuestion.fulfilled, (state, action) => {
        state.status = action.payload.isSuccess ? 'Success' : 'Failed';
        state.response = action?.payload;
      })
      .addCase(updateWeeklyQuestion.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const updateWeeklyQuestionState = (state) => state.UpdateWeeklyQuestion;
export default UpdateWeeklyQuestion.reducer;
