/* eslint-disable quotes */
import { forwardRef } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';

import { NavItemProps, NavItemStateProps } from 'Components/Navbar/types';
import { Iconify } from 'Components/Navbar/Iconify';

import { useNavItem } from '../hooks';
import { stateClasses, sharedStyles } from '../styles';

export const NavItem = forwardRef<HTMLButtonElement, NavItemProps>(
  (
    {
      path,
      icon,
      info,
      title,
      caption,
      open,
      depth,
      render,
      active,
      disabled,
      hasChild,
      slotProps,
      externalLink,
      enabledRootRedirect,
      ...other
    },
    ref,
  ) => {
    const navItem = useNavItem({
      path,
      icon,
      info,
      depth,
      render,
      hasChild,
      externalLink,
      enabledRootRedirect,
    });

    return (
      <StyledNavItem
        ref={ref}
        aria-label={title}
        depth={depth}
        active={active}
        disabled={disabled}
        open={open && !active}
        sx={{
          ...slotProps?.sx,
          '&:hover': {
            backgroundColor: '#282F37',
            color: 'white',
          },
        }}
        className={stateClasses({ open: open && !active, active, disabled })}
        {...navItem.baseProps}
        {...other}
      >
        {icon && (
          <Box component='span' className='mnl__nav__item__icon'>
            {navItem.renderIcon}
          </Box>
        )}

        {title && (
          <Box component='span' className='mnl__nav__item__texts'>
            <Box component='span'>{title}</Box>

            {caption && (
              <Tooltip title={caption} placement='top-start'>
                <Box component='span' className='mnl__nav__item__caption'>
                  {caption}
                </Box>
              </Tooltip>
            )}
          </Box>
        )}

        {info && (
          <Box component='span' className='mnl__nav__item__info'>
            {navItem.renderInfo}
          </Box>
        )}

        {hasChild && (
          <Iconify
            icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
            className='mnl__nav__item__arrow'
          />
        )}
      </StyledNavItem>
    );
  },
);

// ----------------------------------------------------------------------

const StyledNavItem = styled(ButtonBase, {
  shouldForwardProp: (prop) =>
    prop !== 'active' && prop !== 'open' && prop !== 'disabled' && prop !== 'depth',
})<NavItemStateProps>(({ active, open, disabled, depth, theme }) => {
  const rootItem = depth === 1;

  const subItem = !rootItem;

  const baseStyles = {
    item: {
      width: '100%',
      padding: '4px 8px 4px 12px',
      borderRadius: '4px',
      color: 'white',
      '&:hover': {
        backgroundColor: '#282F37',
        color: 'white',
      },
    },
    texts: { minWidth: 0, flex: '1 1 auto' },
    title: {
      ...sharedStyles.noWrap,
      ...theme.typography.body2,
      fontWeight: active ? theme.typography.fontWeightBold : theme.typography.fontWeightMedium,
    },
    caption: {
      ...sharedStyles.noWrap,
      ...theme.typography.caption,
      color: 'white',
    },
    icon: {
      ...sharedStyles.icon,
      width: '24px',
      height: '24px',
      marginRight: '12px',
    },
    arrow: { ...sharedStyles.arrow },
    info: { ...sharedStyles.info },
  } as const;

  return {
    /**
     * Root item
     */
    ...(rootItem && {
      ...baseStyles.item,
      minHeight: '44px',
      ['& .mnl__nav__item__icon']: { ...baseStyles.icon },
      ['& .mnl__nav__item__texts']: { ...baseStyles.texts },
      ['& .mnl__nav__item__title']: { ...baseStyles.title },
      ['& .mnl__nav__item__caption']: { ...baseStyles.caption },
      ['& .mnl__nav__item__info']: { ...baseStyles.info },
      ['& .mnl__nav__item__arrow']: { ...baseStyles.arrow },
      // State
      ...(active && {
        color: 'black',
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: '#282F37',
          color: 'white',
        },
      }),
      ...(open && {
        color: 'black',
        backgroundColor: 'white',
      }),
    }),
    /**
     * Sub item
     */
    ...(subItem && {
      ...baseStyles.item,
      minHeight: '36px',
      ['& .mnl__nav__item__icon']: { ...baseStyles.icon },
      ['& .mnl__nav__item__texts']: { ...baseStyles.texts },
      ['& .mnl__nav__item__title']: { ...baseStyles.title },
      ['& .mnl__nav__item__caption']: { ...baseStyles.caption },
      ['& .mnl__nav__item__info']: { ...baseStyles.info },
      ['& .mnl__nav__item__arrow']: { ...baseStyles.arrow },
      // Shape
      '&::before': {
        left: 0,
        content: '""',
        position: 'absolute',
        width: '12px',
        height: '12px',
        transform: 'translate(calc(12px * -1), calc(12px * -0.4))',
        backgroundColor: '#282F37',
        mask: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none' viewBox='0 0 14 14'%3E%3Cpath d='M1 1v4a8 8 0 0 0 8 8h4' stroke='%23efefef' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E") no-repeat 50% 50%/100% auto`,
        WebkitMask: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none' viewBox='0 0 14 14'%3E%3Cpath d='M1 1v4a8 8 0 0 0 8 8h4' stroke='%23efefef' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E") no-repeat 50% 50%/100% auto`,
      },
      // State
      ...(active && {
        color: 'white',
        backgroundColor: '#484848',
      }),
      ...(open && {
        color: 'white',
        backgroundColor: '#484848',
      }),
    }),
    /**
     * Disabled
     */
    ...(disabled && sharedStyles.disabled),
  };
});
