import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';
import { createSystemLogs } from '../Common/CreateSystemLogs';

const updateBatchStepFourURL = '/api/v1/internal-service/batch/update/step4';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const updateBatchStepFour = createAsyncThunk(
  'updateBatchStepFour',
  async (request, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    try {
      // Grab batch questions from state
      const {
        GetCustomForms: { response },
      } = getState();

      let questionsWithID = [];

      // removing ID from Array
      const info = request?.form?.map((item) => {
        /* eslint-disable camelcase */
        const custom_form_id = item?.id;
        delete item['id'];
        questionsWithID.push({ custom_form_id, ...item });
        return item;
      });
      request.form = info;

      const batchInfo = {
        feature_type_id: request?.batch_id,
        feature_type: 'BATCH',
        action: 'Update',
        column_name: 'Additional Questions',
        from_value: JSON.stringify(response?.results),
        to_value: JSON.stringify(questionsWithID),
      };

      const result = await axios.post(updateBatchStepFourURL, request);
      // create system logs for batch step Four
      if (result?.data?.data?.isSuccess && questionsWithID?.length > 0) {
        dispatch(createSystemLogs(batchInfo));
      }
      return fulfillWithValue(result?.data?.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const UpdateBatchStepFourSlice = createSlice({
  name: 'UpdateBatchStepFour',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate update batch state handling*/
      .addCase(updateBatchStepFour.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(updateBatchStepFour.fulfilled, (state, action) => {
        state.status = action.payload.isSuccess ? 'Success' : 'Failed';
        state.response = action?.payload?.payload;
      })
      .addCase(updateBatchStepFour.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getUpdateBatchStepFourState = (state) => state.UpdateBatchStepFour;
export default UpdateBatchStepFourSlice.reducer;
