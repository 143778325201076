import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const getIEMURL = '/api/v1/internal-service/group/iem';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const getIEM = createAsyncThunk('getIEM', async (request, { rejectWithValue }) => {
  const result = await axios
    .post(getIEMURL, request)
    .then((response) => {
      return response?.data?.data;
    })
    .catch((error) => {
      throw rejectWithValue(error);
    });
  return result;
});

const GetIEMSlice = createSlice({
  name: 'GetIEM',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate getIEM state handling*/
      .addCase(getIEM.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getIEM.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload;
      })
      .addCase(getIEM.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getIEMState = (state) => state.GetIEM;
export default GetIEMSlice.reducer;
