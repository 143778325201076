import createNotesReducer from '../Slices/Notes/CreateNotes';
import deleteNotesReducer from '../Slices/Notes/DeleteNote';
import updateNotesReducer from '../Slices/Notes/UpdateNotes';
import getNotesReducer from '../Slices/Notes/GetNotes';

export const notesReducer = {
  CreateNotes: createNotesReducer,
  DeleteNotes: deleteNotesReducer,
  UpdateNotes: updateNotesReducer,
  NotesByInternID: getNotesReducer,
};
