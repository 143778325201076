import { DateTime } from 'luxon';

interface FormattedDateUtilResult {
  formattedDate: string;
  error: string | null;
}

const formatTimestamp = (
  timestamp: number | string,
  fromIso: boolean = false,
  format: string = 'dd LLL, yyyy',
): FormattedDateUtilResult => {
  try {
    if (fromIso) {
      const formatted = DateTime.fromISO(timestamp).toFormat(format);
      return { formattedDate: formatted, error: null };
    }
    const dateTime = DateTime.fromSeconds(timestamp);
    const formatted = dateTime.toFormat(format);
    return { formattedDate: formatted, error: null };
  } catch (error) {
    return { formattedDate: '', error: error.message };
  }
};

export default formatTimestamp;

export const checkTheValueIsValid = (value) => {
  if (value !== null && value !== undefined && value !== '') {
    return true;
  }
  return false;
};

export const removeFalsyValuesFromObject = (obj, excludedValues = []) => {
  const newObj = { ...obj };
  for (const key in newObj) {
    if (!excludedValues.includes(key) && !newObj[key]) {
      delete newObj[key];
    }
  }
  return newObj;
};

export const daysDifferenceFromToday = (timeStamp: number) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const differenceInMs = timeStamp * 1000 - Date.now();
  const differenceInDays = Math.floor(differenceInMs / oneDay);
  return differenceInDays;
};

export function addHttpProtocol(inputString) {
  if (!inputString.startsWith('http://') && !inputString.startsWith('https://')) {
    return 'http://' + inputString;
  }
  return inputString;
}
