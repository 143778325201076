import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const getAasURL = '/api/v1/internal-service/group/get/7';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
  isLoading: false,
};
export const getAasList = createAsyncThunk(
  'getAasList',
  async (request, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await axios.get(getAasURL);
      if (result?.data?.data?.isSuccess) {
        return fulfillWithValue(result?.data?.data?.payload);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const GetAasSlice = createSlice({
  name: 'getAas',
  initialState,
  reducers: {},
  extraReducers: {
    [getAasList.pending]: (state) => {
      state.status = 'Pending';
      state.isLoading = true;
    },
    [getAasList.fulfilled]: (state, action) => {
      state.status = 'Success';
      state.response = action?.payload;
      state.isLoading = false;
    },
    [getAasList.rejected]: (state, error) => {
      state.status = 'Failed';
      state.error = error;
      state.isLoading = false;
    },
  },
});

export const getAasState = (state) => state.GetAas;
export default GetAasSlice.reducer;
