import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const HostCompanyListURL = '/api/v1/internal-service/host-company/get-list';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const getHostCompanyList = createAsyncThunk('getHostCompanyList', async (request, { rejectWithValue }) => {
  const result = await axios
    .post(HostCompanyListURL, request)
    .then((response) => {
      return response?.data?.data;
    })
    .catch((error) => {
      throw rejectWithValue(error);
    });
  return result;
});

const GetHostCompanyList = createSlice({
  name: 'GetHostCompanyList',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate getIEM state handling*/
      .addCase(getHostCompanyList.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getHostCompanyList.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload;
      })
      .addCase(getHostCompanyList.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getHostCompanyListState = (state) => state.GetHostCompanyList;
export default GetHostCompanyList.reducer;
