import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const revertAcceptanceUrl = '/api/v1/internal-service/offers/update-offer-status';

export const RevertOfferAcceptanceApi = createApi({
  reducerPath: 'revertOfferAcceptanceApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    updateOfferStatus: builder.mutation({
      query: (payload) => {
        return {
          url: revertAcceptanceUrl,
          method: 'POST',
          body: payload,
        };
      },
    }),
  }),
});

export const { useUpdateOfferStatusMutation } = RevertOfferAcceptanceApi;

export default RevertOfferAcceptanceApi.reducer;
