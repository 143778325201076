import { useState, useEffect, useCallback } from 'react';

import { NavItem } from './NavItem';
import { NavUl, NavLi, NavCollapse } from '../styles';

import { isExternalLink, useActiveLink, usePathname } from 'Hooks/routes';
import { NavListProps, NavSubListProps } from 'Components/Navbar/types';

export function NavList({ data, render, depth, slotProps, enabledRootRedirect }: NavListProps) {
  const pathname = usePathname();
  const active = useActiveLink(data?.path, !!data?.children);
  const [openMenu, setOpenMenu] = useState(active);

  useEffect(() => {
    if (!active) {
      handleCloseMenu();
    }
  }, [pathname]);

  const handleToggleMenu = useCallback(() => {
    if (data?.children) {
      setOpenMenu((prev) => !prev);
    }
  }, [data?.children]);

  const handleCloseMenu = useCallback(() => {
    setOpenMenu(false);
  }, []);

  const renderNavItem = (
    <NavItem
      render={render}
      // slots
      path={data?.path}
      icon={data?.icon}
      info={data?.info}
      title={data?.title}
      caption={data?.caption}
      // state
      depth={depth}
      active={active}
      disabled={data?.disabled}
      hasChild={!!data?.children}
      open={data?.children && openMenu}
      externalLink={isExternalLink(data?.path)}
      enabledRootRedirect={enabledRootRedirect}
      // styles
      slotProps={depth === 1 ? slotProps?.rootItem : slotProps?.subItem}
      // actions
      onClick={handleToggleMenu}
    />
  );

  // Hidden item by role
  if (data?.roles && slotProps?.currentRole) {
    if (!data?.roles?.includes(slotProps?.currentRole)) {
      return null;
    }
  }

  // Has children
  if (data?.children) {
    return (
      <NavLi
        disabled={data?.disabled}
        sx={{
          ['& .mnl__nav__li']: {
            '&:first-of-type': { mt: 1 },
          },
        }}
      >
        {renderNavItem}

        <NavCollapse data-group={data?.title} in={openMenu} depth={depth} unmountOnExit mountOnEnter>
          <NavSubList
            data={data?.children}
            render={render}
            depth={depth}
            slotProps={slotProps}
            enabledRootRedirect={enabledRootRedirect}
          />
        </NavCollapse>
      </NavLi>
    );
  }

  // Default
  return <NavLi disabled={data?.disabled}>{renderNavItem}</NavLi>;
}

function NavSubList({ data, render, depth, slotProps, enabledRootRedirect }: NavSubListProps) {
  return (
    <NavUl sx={{ gap: 1 }}>
      {data.map((list) => (
        <NavList
          key={list?.title}
          data={list}
          render={render}
          depth={depth + 1}
          slotProps={slotProps}
          enabledRootRedirect={enabledRootRedirect}
        />
      ))}
    </NavUl>
  );
}
