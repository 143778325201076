import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';
import { createSystemLogs } from '../Common/CreateSystemLogs';

const deleteNotesURL = '/api/v1/internal-service/note/delete-note/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

/** @request
{
    "note_id": 12
}
 */

export const deleteNotes = createAsyncThunk(
  'deleteNotes',
  async (request, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      // creating notes object
      const notesInfo = {
        feature_type_id: request?.intern_id,
        feature_type: 'INTERN',
        action: 'Delete',
        column_name: 'Intern Notes',
        from_value: ' ',
        to_value: request?.note_content,
      };

      // removing intern_id property from request object
      delete request['intern_id'];
      delete request['note_content'];
      const result = await axios.delete(deleteNotesURL + request['note_id']);

      if (result?.data?.data?.isSuccess) {
        dispatch(createSystemLogs(notesInfo));
      }
      return fulfillWithValue(result?.data?.data);
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);

const DeleteNotesSlice = createSlice({
  name: 'DeleteNotes',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate deleteNotes state handling*/
      .addCase(deleteNotes.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(deleteNotes.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.payload;
      })
      .addCase(deleteNotes.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getDeleteNotesState = (state) => state.DeleteNotes;
export default DeleteNotesSlice.reducer;
