import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import intl from 'react-intl-universal';
import axios from '../../../Axios';
import { openNotification } from '../../../Components/Common/Alert';
import { getUserList } from './GetAllUsers';

const updateUserStatusURL = '/api/v1/internal-service/employee/update-status';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const updateUserStatus = createAsyncThunk(
  'updateUserStatus',
  async (request, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const result = await axios.post(updateUserStatusURL, request);
      if (result?.data?.data?.isSuccess) {
        dispatch(getUserList({}));
      }
      return fulfillWithValue(result.data);
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);

const UpdateUserStatusSlice = createSlice({
  name: 'UpdateUserStatus',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate get all user list state handling*/
      .addCase(updateUserStatus.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(updateUserStatus.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.data;

        if (action?.payload?.data?.isSuccess) {
          if (action?.meta?.arg?.status === 'ACTIVE') {
            openNotification('', intl.get('partner.partnerManagement.actived.message'), 2);
          } else {
            openNotification('', intl.get('partner.partnerManagement.deactived.message'), 2);
          }
        } else {
          const getErrorMessage = (description) =>
            notification.error({
              message: '',
              description,
              duration: 2,
              placement: 'topRight',
            });
          if (action?.meta?.arg?.status === 'ACTIVE') {
            getErrorMessage(intl.get('partner.partnerManagement.actived.error.message'));
          } else {
            getErrorMessage(intl.get('partner.partnerManagement.deactived.error.message'));
          }
        }
      })
      .addCase(updateUserStatus.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getUpdateUserState = (state) => state.UpdateUser;
export default UpdateUserStatusSlice.reducer;
