import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const updateLowEnglishProficiency4URL =
  '/api/v1/internal-service/intern-application/update-question/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
  count: 0,
};
export const updateLowEnglishProficiency4 = createAsyncThunk(
  'updateLowEnglishProficiency4',
  async (request, { rejectWithValue }) => {
    let internId = request.intern_id;
    delete request['intern_id'];
    const result = await axios
      .post(updateLowEnglishProficiency4URL + internId + '/low-english-proficiency-4', request)
      .then((response) => {
        return response?.data?.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const updateLowEnglishProficiency4Slice = createSlice({
  name: 'LowEnglishProficiency4',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate update low-english-proficiency-4 state handling*/
      .addCase(updateLowEnglishProficiency4.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(updateLowEnglishProficiency4.fulfilled, (state, action) => {
        state.status = action?.payload?.isSuccess ? 'Success' : 'Failed';
        state.response = action?.payload?.payload;
        state.count = action?.payload?.count;
      })
      .addCase(updateLowEnglishProficiency4.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const updateLowEnglishProficiency4State = (state) => state.LowEnglishProficiency4;
export default updateLowEnglishProficiency4Slice.reducer;
