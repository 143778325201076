export const configs = {
  REACT_APP_BASE_URL: process.env.REACT_APP_BASE_URL,
  REACT_APP_BASE_URL_OTHR: process.env.REACT_APP_BASE_URL_OTHR,
  REACT_APP_ENV: process.env.NODE_ENV,
  FE_BASE_URL: () => {
    return window.location.origin;
  },
  EXT_FE_BASE_URL: process.env.REACT_APP_EXT_FE_BASE_URL,
  REACT_APP_ASSET_BASE_URL: process.env.REACT_APP_ASSET_BASE_URL,
  FRESHDESK_URL: process.env.REACT_APP_FRESHDESK_BASE_URL,
};
