import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';
import { createMassSystemLogs } from '../Common/CreateMassSystemLogs';

const assignBulkInternsToAasURL = '/api/v1/internal-service/intern-application/bulk-add-aa';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const assignBulkInternsToAas = createAsyncThunk(
  'assignBulkInternsToAas',
  async (request, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    try {
      // Grab the state from store
      const {
        GetInternsListByAas: { response },
        GetAas: { response: aasResponse },
      } = getState();

      // To Find the intern detail by using ID
      const findInternDetailsById = (id) => {
        return response?.find((item) => item?.application?.application_id === id);
      };

      // To Find the aa detail by using ID
      const findAasDetailsById = (id) => {
        return aasResponse?.employees?.find((item) => item?.employee_id === id);
      };

      // create mass system logs for Assigning AA to intern
      const internsInfo = request?.application_ids
        ?.map((id) => {
          const internPreviousAaDetails = findInternDetailsById(id);
          const logObj = {
            feature_type_id: internPreviousAaDetails?.intern_id,
            feature_type: 'INTERN',
            action: 'Update',
            column_name: 'Assigned AA',
            from_value:
              internPreviousAaDetails?.application?.applicationAdvisor !== null
                ? internPreviousAaDetails?.application?.applicationAdvisor?.full_name
                : ' ',
            to_value: findAasDetailsById(request?.aa_id)?.full_name,
          };
          if (logObj?.from_value !== logObj?.to_value) {
            return logObj;
          }
        })
        ?.filter((item) => item !== undefined);

      const result = await axios.post(assignBulkInternsToAasURL, request);
      if (result?.data?.data?.isSuccess && internsInfo.length > 0) {
        dispatch(createMassSystemLogs(internsInfo));
      }
      if (result?.data?.data?.isSuccess) {
        return fulfillWithValue(result?.data?.data);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const assignBulkInternsToAasSlice = createSlice({
  name: 'assignBulkInternsToAas',
  initialState,
  reducers: {
    assignBulkInternsToAasStateReset: (state) => {
      state.status = 'idle';
      state.error = null;
      state.response = null;
      state.isLoading = false;
    },
  },
  extraReducers: {
    [assignBulkInternsToAas.pending]: (state) => {
      state.status = 'Pending';
    },
    [assignBulkInternsToAas.fulfilled]: (state, action) => {
      state.status = 'Success';
      state.response = action?.payload;
    },
    [assignBulkInternsToAas.rejected]: (state, error) => {
      state.status = 'Failed';
      state.error = error;
    },
  },
});

export const assignBulkInternsToAasState = (state) => state.AssignBulkInternsToAas;
export const { assignBulkInternsToAasStateReset } = assignBulkInternsToAasSlice.actions;
export default assignBulkInternsToAasSlice.reducer;
