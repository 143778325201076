import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';
import { createMassSystemLogs } from '../Common/CreateMassSystemLogs';

const bulkAssignPAMURL = '/api/v1/internal-service/host-company/bulk-assign-pam';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const bulkAssignPAM = createAsyncThunk(
  'bulkAssignPAM',
  async (request, { rejectWithValue, dispatch, fulfillWithValue, getState }) => {
    try {
      const {
        GetCompanyListPAMWise: { response },
        GetPAMList,
      } = getState();

      // Get the PM Details
      const pmDetails = GetPAMList?.response?.payload;

      // To Find the Host Company detail by using ID
      const findHostCompanyDetailsById = (id) => {
        return response?.payload?.find((item) => item?.host_company_id === id);
      };

      // To Find the PM detail by using ID
      const findPmDetailsById = (id) => {
        return pmDetails?.find((item) => item?.employee_id === id);
      };

      // create mass system logs for Assigning PM to Host Company
      const hostCompanyInfo = request?.host_company_ids
        ?.map((id) => {
          const previousHostCompanyPmDetails = findHostCompanyDetailsById(id);
          const logObj = {
            feature_type_id: id,
            feature_type: 'COMPANY',
            action: 'Update',
            column_name: 'Assigned PM',
            from_value:
              previousHostCompanyPmDetails?.pam !== null
                ? previousHostCompanyPmDetails?.pam?.full_name
                : '  ',
            to_value: findPmDetailsById(request?.pam_id)?.full_name,
          };
          if (logObj?.from_value !== logObj?.to_value) {
            return logObj;
          }
        })
        ?.filter((item) => item !== undefined);

      console.log('hostCompanyInfo', hostCompanyInfo);
      const result = await axios.post(bulkAssignPAMURL, request);
      if (result?.data?.data?.isSuccess && hostCompanyInfo.length > 0) {
        dispatch(createMassSystemLogs(hostCompanyInfo));
      }
      return fulfillWithValue(result.data);
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);

const BulkAssignPAM = createSlice({
  name: 'BulkAssignPAM',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate assignIEMBulk state handling*/
      .addCase(bulkAssignPAM.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(bulkAssignPAM.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.data;
      })
      .addCase(bulkAssignPAM.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const bulkAssignPAMState = (state) => state.BulkAssignPAM;
export default BulkAssignPAM.reducer;
