import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const copyUserGroupsURL = '/api/v1/internal-service/group/copy/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const copyUserGroup = createAsyncThunk(
  'copyUserGroup',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .get(copyUserGroupsURL + request.group_id)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const CopyUserGroupsSlice = createSlice({
  name: 'CopyUserGroup',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate copy user group state handling*/
      .addCase(copyUserGroup.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(copyUserGroup.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.data?.payload;
      })
      .addCase(copyUserGroup.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getCopyUserGroupState = (state) => state.CopyUserGroup;
export default CopyUserGroupsSlice.reducer;
