/* A redux-toolkit slice. */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';
import { createMassSystemLogs } from '../Common/CreateMassSystemLogs';

const updateBatchStepOneURL = '/api/v1/internal-service/batch/update/step1/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const updateBatchStepOne = createAsyncThunk(
  'updateBatchStepOne',
  async (request, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    try {
      const batchId = request?.batch_id;
      const changedValues = request?.changedValues?.filter((item) => item?.touched === true);
      const {
        BatchByID: { response: batchDetails },
      } = getState();

      const changedBatchFields = changedValues?.map((item) => {
        if (batchDetails[item?.name[0]] !== item?.value) {
          const batchInfo = {
            feature_type_id: batchId,
            feature_type: 'BATCH',
            action: 'Update',
            column_name: item?.name[0],
            from_value: batchDetails[item?.name[0]]?.toString(),
            to_value: item?.value?.toString(),
          };
          return batchInfo;
        }
      });

      // delete changedValues.batch_id;
      delete request['batch_id'];
      delete request['changedValues'];

      // updating batch step one
      const result = await axios.post(updateBatchStepOneURL + batchId, request);

      // create system logs for batch step one
      if (result?.data?.data?.isSuccess && changedValues.length > 0) {
        dispatch(createMassSystemLogs(changedBatchFields));
      }
      return fulfillWithValue(result?.data?.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const UpdateBatchStepOneSlice = createSlice({
  name: 'UpdateBatchStepOne',
  initialState,
  reducers: {
    resetBatchDetails: () => initialState,
  },
  extraReducers(builder) {
    builder
      /* generate update batch state handling*/
      .addCase(updateBatchStepOne.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(updateBatchStepOne.fulfilled, (state, action) => {
        state.status = action?.payload?.isSuccess ? 'Success' : 'Failed';
        state.response = action?.payload?.payload;
        if (!action?.payload?.isSuccess) state.error = action?.payload?.message;
      })
      .addCase(updateBatchStepOne.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getUpdateBatchStepOneState = (state) => state.UpdateBatchStepOne;
export const { resetBatchDetails } = UpdateBatchStepOneSlice.actions;
export default UpdateBatchStepOneSlice.reducer;
