export const slotProps = {
  subheader: {
    fontSize: 11,
    fontWeight: 700,
  },
  rootItem: {
    sx: {
      fontSize: 14,
      fontWeight: 600,
    },
  },
  subItem: {
    sx: {
      fontSize: 14,
      fontWeight: 500,
    },
  },
};

export const slotPropsMini = {
  rootItem: {
    sx: {
      fontSize: 12,
      fontWeight: 600,
    },
  },
  subItem: {
    sx: {
      fontSize: 14,
      fontWeight: 500,
      color: 'white',
    },
  },
  paper: {
    background: 'black',
    overflow: 'auto',
    paddingTop: '12px',
  },
};
