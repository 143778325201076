import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const getUserProfileURL = '/api/v1/internal-service/employee/me';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const getUserProfile = createAsyncThunk(
  'getUserProfile',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .get(getUserProfileURL)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log('getUserProfile-Catch', request)
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetUserProfileSlice = createSlice({
  name: 'UserProfile',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate get all user list state handling*/
      .addCase(getUserProfile.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.data;
        localStorage.setItem('TIMEZONE', action?.payload?.data?.payload?.timezone_name || 'ETC/UTC');
      })
      .addCase(getUserProfile.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getUserProfileState = (state) => state.UserProfile;
export default GetUserProfileSlice.reducer;
