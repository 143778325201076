import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const GetAdditionalInfoURL = '/api/v1/internal-service/host-company/get-additional-details';

export const GetAdditionalInfo = createApi({
  reducerPath: 'getAdditionalInfo',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getAdditionalInfo: builder.query({
      query: (request) => ({ url: GetAdditionalInfoURL, method: 'POST', body: request }),
    }),
  }),
});

export const { useGetAdditionalInfoQuery } = GetAdditionalInfo;
