import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';
import { createSystemLogs } from '../Common/CreateSystemLogs';

const createBatchStepOneURL = '/api/v1/internal-service/batch/create/step1';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const createBatchStepOne = createAsyncThunk(
  'createBatchStepOne',
  async (request, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const result = await axios.post(createBatchStepOneURL, request);
      const partnerInfo = {
        feature_type_id: result?.data?.data?.payload?.batch_id,
        feature_type: 'BATCH',
        action: 'Create',
        column_name: 'BATCH',
        from_value: ' ',
        to_value: 'Batch Created',
      };
      if (result?.data?.data?.isSuccess) {
        dispatch(createSystemLogs(partnerInfo));
      }
      return fulfillWithValue(result?.data?.data);
    } catch (error) {
      throw rejectWithValue(error?.message);
    }
  },
);

const CreateBatchStepOneSlice = createSlice({
  name: 'CreateBatchStepOne',
  initialState,
  extraReducers: {
    [createBatchStepOne.pending]: (state) => {
      state.status = 'Pending';
      state.error = null;
    },
    [createBatchStepOne.fulfilled]: (state, action) => {
      state.status = action.payload.isSuccess ? 'Success' : 'Failed';
      state.response = action?.payload?.payload;
    },
    [createBatchStepOne.rejected]: (state, action) => {
      state.status = 'Failed';
      state.error = action?.payload;
    },
  },
});

export const getCreateBatchStepOneState = (state) => state.CreateBatchStepOne;
export default CreateBatchStepOneSlice.reducer;
