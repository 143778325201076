import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../Axios/AxiosInstance';

const shortlistedHcUrl = '/api/v1/internal-service/intern/';
const internInterestUrl = '/api/v1/internal-service/IO/';
const sendReminderUrl = '/api/v1/internal-service/host-company/';
export const ShortlistedHcsApi = createApi({
  reducerPath: 'shortlistedHcsApi',
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getShortlistedHcs: builder.query({
      query: ({ internId }) => {
        return { url: `${shortlistedHcUrl}${internId}/shortlist/companies` };
      },
    }),
    getInternInterests: builder.query({
      query: ({ internId }) => {
        return { url: `${internInterestUrl}${internId}/interested-irs` };
      },
    }),
    sendReminder: builder.mutation({
      query: ({ reminderType, ...body }) => {
        const url = `${sendReminderUrl}${reminderType}/reminder`;
        return {
          url,
          method: 'POST',
          data: body,
        };
      },
    }),
  }),
});

export const { useGetShortlistedHcsQuery, useGetInternInterestsQuery, useSendReminderMutation } =
  ShortlistedHcsApi;

export default ShortlistedHcsApi.reducer;
