import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const createBatchStepThreeURL = '/api/v1/internal-service/batch/create/step3';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const createBatchStepThree = createAsyncThunk(
  'createBatchStepThree',
  async (request, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await axios.post(createBatchStepThreeURL, request);
      return fulfillWithValue(result?.data?.data);
    } catch (error) {
      throw rejectWithValue(error?.message);
    }
  },
);

const CreateBatchStepThreeSlice = createSlice({
  name: 'CreateBatchStepThree',
  initialState,
  extraReducers: {
    [createBatchStepThree.pending]: (state) => {
      state.status = 'Pending';
      state.error = null;
    },
    [createBatchStepThree.fulfilled]: (state, action) => {
      state.status = action.payload.isSuccess ? 'Success' : 'Failed';
      state.response = action?.payload?.payload;
    },
    [createBatchStepThree.rejected]: (state, action) => {
      state.status = 'Failed';
      state.error = action?.payload;
    },
  },
});

export const getCreateBatchStepThreeState = (state) => state.CreateBatchStepThree;
export default CreateBatchStepThreeSlice.reducer;
