import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';
import { createMassSystemLogs } from '../Common/CreateMassSystemLogs';

const approveInternBatchURL = '/api/v1/internal-service/intern/approve-batch';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const approveInternBatch = createAsyncThunk(
  'approveInternBatch',
  async (request, { rejectWithValue, getState, fulfillWithValue, dispatch }) => {
    try {
      // grab state from store
      const { BatchWiseInternList } = getState();

      // creating an array of intern details with their respective ids
      const approveInternsList = request?.intern_data?.map((item) => {
        const internDetails = BatchWiseInternList?.response?.find(
          (intern) => intern?.intern_id === item?.intern_id,
        );
        return {
          feature_type_id: item?.intern_id,
          feature_type: 'INTERN',
          action: 'Update',
          column_name: 'Approve Intern Batch',
          from_value: 'Not approved',
          to_value: `Approved for partner ${internDetails?.partner?.partner_name} and batch ${internDetails?.batch?.batch_name}`,
        };
      });
      const result = await axios.post(approveInternBatchURL, request);
      if (result?.data?.data?.isSuccess) {
        dispatch(createMassSystemLogs(approveInternsList));
      }
      return fulfillWithValue(result.data);
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);

const ApproveInternBatchSlice = createSlice({
  name: 'ApproveInternBatch',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate approveInternBatch state handling*/
      .addCase(approveInternBatch.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(approveInternBatch.fulfilled, (state, action) => {
        state.status = action?.payload?.data?.isSuccess ? 'Success' : 'Failed';
        state.response = action?.payload?.data?.payload;
      })
      .addCase(approveInternBatch.rejected, (state, action) => {
        state.status = 'Failed';
        state.response = action?.payload?.response
          ? action?.payload?.response
          : { data: action.payload?.payload };
      });
  },
});

export const getApproveInternBatchState = (state) => state.ApproveInternBatch;
export default ApproveInternBatchSlice.reducer;
