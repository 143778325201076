import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const updateNotesURL = '/api/v1/internal-service/note/update-note/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

/** @request
{
    "intern_id":17,
    "note_content":"test",
    "note_id": 12
}
 */

export const updateNotes = createAsyncThunk('updateNotes', async (request, { rejectWithValue }) => {
  const noteId = request['note_id'];
  delete request['note_id'];
  const result = await axios
    .post(updateNotesURL + noteId, request)
    .then((response) => {
      return response?.data?.data;
    })
    .catch((error) => {
      throw rejectWithValue(error);
    });
  return result;
});

const UpdateNotesSlice = createSlice({
  name: 'UpdateNotes',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate updateNotes state handling*/
      .addCase(updateNotes.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(updateNotes.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.payload;
      })
      .addCase(updateNotes.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getUpdateNotesState = (state) => state.UpdateNotes;
export default UpdateNotesSlice.reducer;
