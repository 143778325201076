import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';
import { createSystemLogs } from '../Common/CreateSystemLogs';

const createCompanyNotesURL = '/api/v1/internal-service/company-note/create-note';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};


export const createCompanyNotes = createAsyncThunk(
  'createCompanyNotes',
  async (request, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const result = await axios.post(createCompanyNotesURL, request);
      // creating notes object
      const notesInfo = {
        feature_type_id: request?.host_company_id,
        feature_type: 'COMPANY',
        action: 'Create',
        column_name: 'Host Company Notes',
        from_value: ' ',
        to_value: request?.note_content,
      };
      if (result?.data?.data?.isSuccess) {
        dispatch(createSystemLogs(notesInfo));
      }
      return fulfillWithValue(result?.data?.data);
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);

const CreateCompanyNotesSlice = createSlice({
  name: 'CreateCompanyNotes',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate createCompanyNotes state handling*/
      .addCase(createCompanyNotes.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(createCompanyNotes.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.payload;
      })
      .addCase(createCompanyNotes.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getCreateCompanyNotesState = (state) => state.CreateCompanyNotes;
export default CreateCompanyNotesSlice.reducer;
