import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosInstance';

import { objectToQueryString } from '../../../Util/utility';

const url = '/api/v1/internal-service/dashboard/';

export const GetAnalyticsDetailsApi = createApi({
  reducerPath: 'GetAnalyticsDetailsApi',
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getAnalyticsDetails: builder.query({
      query: (request) => {
        const queryString = objectToQueryString(request);
        const updatedUrl = queryString ? `${url}?${queryString}` : url;
        return { url: updatedUrl, method: 'GET' };
      },
    }),
  }),
});

export const { useGetAnalyticsDetailsQuery } = GetAnalyticsDetailsApi;

export default GetAnalyticsDetailsApi.reducer;
