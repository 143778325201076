import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';
import { openNotification } from '../../../Components/Common/Alert';
import intl from 'react-intl-universal';
import { notification } from 'antd';

const resetPasswordURL = '/api/v1/internal-service/employee/reset-password';
const resetPasswordNoJwtURL = '/api/v1/internal-service/auth/forgot-password';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const resetPassword = createAsyncThunk(
  'resetPassword',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(request?.isPublic ? resetPasswordNoJwtURL : resetPasswordURL, { email: request.email })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const ResetPasswordSlice = createSlice({
  name: 'ResetPassword',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate send remainder state handling*/
      .addCase(resetPassword.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.status = action?.payload?.data?.isSuccess ? 'Success' : 'Failed';
        state.response = action?.payload;

        if (action?.payload?.data?.isSuccess) {
          openNotification(
            intl.get('user.resetPassword.header'),
            intl.get('user.resetPassword', { email: action?.payload?.data?.payload?.email }),
            2,
          );
        } else {
          notification.error({
            message: 'Reset password failed',
            description: 'The email you entered did not match our records. Please double check and try again.',
            duration: 2,
            placement: 'topRight',
          });
        }
      })
      .addCase(resetPassword.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getResetPasswordstate = (state) => state.ResetPassword;
export default ResetPasswordSlice.reducer;
