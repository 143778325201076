import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const SUPER_ADMIN = '1';
const LoginURL = '/api/v1/internal-service/auth/sign-in';

const initialState = {
  isLoggedIn: false,
  isTwofaCompleted: false,
  status: 'idle',
  error: null,
  response: null,
  metaData: null,
};

export const UserLogin = createAsyncThunk('login', async (request, { rejectWithValue }) => {
  const result = await axios
    .post(LoginURL, request)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw rejectWithValue(error);
    });
  return result;
});

const loginSlice = createSlice({
  name: 'Login',
  initialState,
  reducers: {
    updateLoggedInUserStatus: (state, action) => {
      state.isLoggedIn = action?.payload;
    },
    setTwofaCompleted: (state) => {
      state.isTwofaCompleted = true;
    },
    resetLoginState: () => initialState,
  },
  extraReducers(builder) {
    builder
      /* generate sign in state handling*/
      .addCase(UserLogin.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(UserLogin.fulfilled, (state, action) => {
        state.status = action.payload?.data?.isSuccess ? 'Success' : 'Failed';
        state.response = action.payload?.data?.isSuccess
          ? action.payload?.data?.payload?.groups
          : action?.payload?.data;
        state.metaData = action?.payload?.data;
      })
      .addCase(UserLogin.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export function isUserAutherisedTo(
  subPageName,
  userGroups = JSON.parse(localStorage.getItem('USER_GROUPS')),
) {
  if (localStorage.getItem('EMPLOYEE_ID') === parseInt(SUPER_ADMIN)) {
    return true;
  }
  if (userGroups == null || userGroups?.length === 0) {
    return false;
  }
  const foundAuth = userGroups.map((item) => {
    const filteredData = item?.permissions?.find(
      (permission) =>
        permission?.permission_name === String(subPageName)?.trim() &&
        permission?.permission_action === 'EDIT',
    );
    return filteredData;
  });
  for (let i = 0; i < foundAuth.length; i++) {
    if (foundAuth[i] === undefined) {
      continue;
    } else if (foundAuth[i]?.permission_name) {
      return true;
    } else return false;
  }
}

export const getLoginState = (state) => state.Login;
export const { setTwofaCompleted, resetLoginState, updateLoggedInUserStatus } = loginSlice.actions;
export default loginSlice.reducer;
