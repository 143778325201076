import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const getManualRecommendedProfilesActiveURL =
  '/api/v1/internal-service/recommendation/get-active';

export const GetManualRecommendedProfilesActiveApi = createApi({
  reducerPath: 'GetManualRecommendedProfilesActiveApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getManualRecommendedProfilesActive : builder.mutation({
      query: (data) => ({ url: getManualRecommendedProfilesActiveURL, method: 'POST' , body : data }),
    }),
  }),
});

export const { useGetManualRecommendedProfilesActiveMutation } = GetManualRecommendedProfilesActiveApi;
export default GetManualRecommendedProfilesActiveApi.reducer;