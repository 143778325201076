import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';
import { createSystemLogs } from '../Common/CreateSystemLogs';

const updatePartnerStatusURL = '/api/v1/internal-service/partner/update-status';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
  count: 0,
};
export const updatePartnerStatus = createAsyncThunk(
  'updatePartnerStatus',
  async (request, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const result = await axios.post(updatePartnerStatusURL, request);
      const partnerInfo = {
        feature_type_id: request?.partner_id,
        feature_type: 'PARTNER',
        action: 'Update',
        column_name: 'Partner status',
        from_value: request?.partner_status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
        to_value: request?.partner_status,
      };
      if (result?.data?.data?.isSuccess) {
        dispatch(createSystemLogs(partnerInfo));
      }
      return fulfillWithValue(result?.data?.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const UpdatePartnerStatusSlice = createSlice({
  name: 'PartnerStatus',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate update partner status state handling*/
      .addCase(updatePartnerStatus.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(updatePartnerStatus.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.payload;
      })
      .addCase(updatePartnerStatus.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getUpdatePartnerStatusState = (state) => state.PartnerStatus;
export default UpdatePartnerStatusSlice.reducer;
