import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const getWeeklyFeedbackURL = '/api/v1/internal-service/weekly-feedback-loop/internships';

export const GetWeeklyFeedbackApi = createApi({
  reducerPath: 'getWeeklyFeedbackApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getWeeklyFeedbackApi: builder.query({
      query: (data) => ({ url: getWeeklyFeedbackURL, method: 'POST', body: data }),
    }),
  }),
});

export const { useGetWeeklyFeedbackApiQuery, usePrefetch } = GetWeeklyFeedbackApi;

export default GetWeeklyFeedbackApi.reducer;
