import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const createBatchStepTwoURL = '/api/v1/internal-service/batch/create/step2';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
  count: 0,
};
export const createBatchStepTwo = createAsyncThunk(
  'createBatchStepTwo',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(createBatchStepTwoURL, request)
      .then((response) => {
        return response?.data?.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const CreateBatchStepTwoSlice = createSlice({
  name: 'CreateBatchStepTwo',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate create batch state handling*/
      .addCase(createBatchStepTwo.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(createBatchStepTwo.fulfilled, (state, action) => {
        state.status = action.payload.isSuccess ? 'Success' : 'Failed';
        state.response = action?.payload?.payload;
      })
      .addCase(createBatchStepTwo.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getCreateBatchStepTwoState = (state) => state.CreateBatchStepTwo;
export default CreateBatchStepTwoSlice.reducer;
