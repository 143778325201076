import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const getInternDetailsByEmailUrl = '/api/v1/internal-service/host-company/interview/intern/';

export const GetInternDetailsByEmailApi = createApi({
  reducerPath: 'internDetailsByEmailApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    internDetailsByEmail: builder.query({
      query: (emailId) => ({
        url: getInternDetailsByEmailUrl + emailId + '?bypass_checks=1',
        method: 'GET',
      }),
    }),
  }),
});

export const { useInternDetailsByEmailQuery } = GetInternDetailsByEmailApi;

export default GetInternDetailsByEmailApi.reducer;
