import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const getCompanyDetailsByIDURL = '/api/v1/internal-service/host-company/get-common-detail/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
  isLoading: false,
};
export const getCompanyDetailsByID = createAsyncThunk(
  'getCompanyDetailsByID',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(getCompanyDetailsByIDURL, request)
      .then((response) => {
        return response?.data?.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetCompanyDetailsByIdSlice = createSlice({
  name: 'CompanyDetailsByID',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      /* generate get CompanyDetails by ID state handling*/
      .addCase(getCompanyDetailsByID.pending, (state) => {
        state.status = 'Pending';
        state.isLoading = true;
      })
      .addCase(getCompanyDetailsByID.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.payload;
        state.isLoading = false;
      })
      .addCase(getCompanyDetailsByID.rejected, (state) => {
        state.status = 'Failed';
        state.isLoading = false;
      });
  },
});

export const getCompanyDetailsByIDState = (state) => state.CompanyDetailsByID;
export const { reset } = GetCompanyDetailsByIdSlice.actions;
export default GetCompanyDetailsByIdSlice.reducer;
