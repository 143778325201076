import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';
import { openNotification } from '../../../Components/Common/Alert';
import intl from 'react-intl-universal';

const sendRemainderURL = '/api/v1/internal-service/employee/send-reminder';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const sendRemainderToUser = createAsyncThunk(
  'sendRemainder',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(sendRemainderURL, { employee_id: request.employee_id })
      .then((response) => {
        response.data['email'] = request.email;
        return response.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const SendRemainderSlice = createSlice({
  name: 'Remainder',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate send remainder state handling*/
      .addCase(sendRemainderToUser.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(sendRemainderToUser.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload;
        openNotification(
          intl.get('user.sendRemainder.header'),
          intl.get('user.sendRemainder', { email: action?.payload?.email }),
          2,
        );
      })
      .addCase(sendRemainderToUser.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getSendRemainderToUserState = (state) => state.SendRemainder;
export default SendRemainderSlice.reducer;
