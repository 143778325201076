import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const sendReminderInterviewSlotURL = '/api/v1/internal-service/intern/reminder-interview';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const sendReminderInterviewSlot = createAsyncThunk('sendReminderInterviewSlot', async (request, { rejectWithValue }) => {
  const result = await axios
    .post(sendReminderInterviewSlotURL, request)
    .then((response) => {
      return response?.data?.data;
    })
    .catch((error) => {
      throw rejectWithValue(error);
    });
  return result;
});

const SendReminderInterviewSlot = createSlice({
  name: 'SendReminderInterviewSlot',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(sendReminderInterviewSlot.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(sendReminderInterviewSlot.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload;
      })
      .addCase(sendReminderInterviewSlot.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const sendReminderInterviewSlotState = (state) => state.SendReminderInterviewSlot;
export default SendReminderInterviewSlot.reducer;
