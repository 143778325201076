import { cloneElement, forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { NavItemProps } from '../types';

interface RouterLinkProps extends Omit<LinkProps, 'to'> {
  href: string;
}

export const RouterLink = forwardRef<HTMLAnchorElement, RouterLinkProps>(
  ({ href, ...other }, ref) => <Link ref={ref} to={href} {...other} />,
);

export type UseNavItemReturn = {
  subItem: boolean;
  rootItem: boolean;
  subDeepItem: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseProps: Record<string, any>;
  renderIcon: React.ReactNode;
  renderInfo: React.ReactNode;
};

export type UseNavItemProps = {
  path: NavItemProps['path'];
  icon?: NavItemProps['icon'];
  info?: NavItemProps['info'];
  depth?: NavItemProps['depth'];
  render?: NavItemProps['render'];
  hasChild?: NavItemProps['hasChild'];
  externalLink?: NavItemProps['externalLink'];
  enabledRootRedirect?: NavItemProps['enabledRootRedirect'];
};

export function useNavItem({
  path,
  icon,
  info,
  depth,
  render,
  hasChild,
  externalLink,
  enabledRootRedirect,
}: UseNavItemProps): UseNavItemReturn {
  const rootItem = depth === 1;

  const subItem = !rootItem;

  const subDeepItem = Number(depth) > 2;

  const linkProps = externalLink
    ? { href: path, target: '_blank', rel: 'noopener' }
    : { component: RouterLink, href: path };

  const baseProps = hasChild && !enabledRootRedirect ? { component: 'div' } : linkProps;

  let renderIcon = null;

  if (icon && render?.navIcon && typeof icon === 'string') {
    renderIcon = render?.navIcon[icon];
  } else {
    renderIcon = icon;
  }

  let renderInfo = null;

  if (info && render?.navInfo && Array.isArray(info)) {
    const [key, value] = info;
    const element = render.navInfo(value)[key];

    renderInfo = element ? cloneElement(element) : null;
  } else {
    renderInfo = info;
  }

  return {
    subItem,
    rootItem,
    subDeepItem,
    baseProps,
    renderIcon,
    renderInfo,
  };
}
