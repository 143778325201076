import ListSubheader, { ListSubheaderProps } from '@mui/material/ListSubheader';
import Collapse, { CollapseProps } from '@mui/material/Collapse';
import Box, { BoxProps } from '@mui/material/Box';
import { Iconify } from '../Iconify';

// ----------------------------------------------------------------------

export function stateClasses({
  open,
  active,
  disabled,
}: {
  open?: boolean;
  active?: boolean;
  disabled?: boolean;
}) {
  let classes = 'mnl__nav__item';

  if (active) {
    classes += ' state--active';
  } else if (open) {
    classes += ' state--open';
  } else if (disabled) {
    classes += ' state--disabled';
  }

  return classes;
}

// ----------------------------------------------------------------------

export const sharedStyles = {
  icon: {
    flexShrink: 0,
    display: 'inline-flex',
    ['& svg, & img, & .mnl__icon__root, & .mnl__svg__color__root']: {
      width: '100%',
      height: '100%',
    },
  },
  arrow: {
    width: 16,
    height: 16,
    flexShrink: 0,
    marginLeft: '6px',
    display: 'inline-flex',
  },
  info: {
    fontSize: 12,
    flexShrink: 0,
    fontWeight: 600,
    marginLeft: '6px',
    lineHeight: 18 / 12,
    display: 'inline-flex',
  },
  noWrap: {
    width: '100%',
    maxWidth: '100%',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  disabled: { opacity: 0.48, pointerEvents: 'none' },
} as const;

// ----------------------------------------------------------------------

export function Subheader({
  sx,
  open,
  children,
  ...other
}: ListSubheaderProps & {
  open?: boolean;
}) {
  return (
    <ListSubheader
      disableSticky
      component='div'
      sx={{
        gap: 1,
        cursor: 'pointer',
        alignItems: 'center',
        position: 'relative',
        typography: 'overline',
        display: 'inline-flex',
        alignSelf: 'flex-start',
        color: 'white',
        padding: '16px 8px 8px 12px',
        fontSize: '11px',
        ...sx,
      }}
      {...other}
    >
      <Iconify
        width={16}
        icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
        sx={{
          left: -4,
          opacity: 0,
          position: 'absolute',
          transition: (theme) =>
            theme.transitions.create(['opacity'], {
              duration: theme.transitions.duration.standard,
            }),
        }}
      />

      {children}
    </ListSubheader>
  );
}

// ----------------------------------------------------------------------

export function NavCollapse({
  sx,
  depth,
  children,
  ...other
}: CollapseProps & {
  depth: number;
}) {
  return (
    <Collapse
      sx={{
        ...(depth + 1 !== 1 && {
          pl: 'calc(8px + 24px / 2)',
          ['& .mnl__nav__ul']: {
            position: 'relative',
            pl: '12px',
            '&::before': {
              top: 0,
              left: 0,
              width: '2px',
              content: '""',
              position: 'absolute',
              bottom: 'calc(36px - 2px - 12px / 2)',
              bgcolor: '#282F37',
            },
          },
        }),
        ...sx,
      }}
      {...other}
    >
      {children}
    </Collapse>
  );
}

// ----------------------------------------------------------------------

export function NavLi({
  sx,
  children,
  disabled,
  ...other
}: BoxProps & {
  disabled?: boolean;
}) {
  return (
    <Box
      component='li'
      className='mnl__nav__li'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        ...(disabled && { cursor: 'not-allowed' }),
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
}

// ----------------------------------------------------------------------

export function NavUl({ children, sx }: BoxProps) {
  return (
    <Box
      component='ul'
      className='mnl__nav__ul'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        ...sx,
        paddingInlineStart: 0,
      }}
    >
      {children}
    </Box>
  );
}
