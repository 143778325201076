import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const editUserGroupURL = '/api/v1/internal-service/group/edit/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const editUserGroup = createAsyncThunk(
  'editUserGroup',
  async (request, { rejectWithValue }) => {
    const groupId = request?.group_id;
    delete request['group_id'];
    const result = await axios
      .post(editUserGroupURL + groupId, request)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const EditUserGroupSlice = createSlice({
  name: 'EditUserGroup',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate edit user group state handling*/
      .addCase(editUserGroup.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(editUserGroup.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.data?.payload;
      })
      .addCase(editUserGroup.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getEditUserGroupState = (state) => state.EditUserGroup;
export default EditUserGroupSlice.reducer;
