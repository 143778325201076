import { useState, useCallback } from 'react';

import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';

import { NavList } from './NavList';
import { NavUl, NavLi, Subheader } from '../styles';
import { NavGroupProps, NavSectionProps } from 'Components/Navbar/types';

export function NavSectionVertical({ data, slotProps }: NavSectionProps) {
  return (
    <Stack component='nav' sx={{ px: 2, flex: '1 1 auto' }}>
      <NavUl sx={{ flex: '1 1 auto', gap: 1 }}>
        {data.map((group) => (
          <Group
            key={group.subheader ?? group?.items?.[0]?.title}
            subheader={group?.subheader}
            items={group?.items}
            slotProps={slotProps}
          />
        ))}
      </NavUl>
    </Stack>
  );
}

function Group({ items, render, subheader, slotProps, enabledRootRedirect }: NavGroupProps) {
  const [open, setOpen] = useState(true);

  const handleToggle = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const renderContent = (
    <NavUl sx={{ gap: 1 }}>
      {items.map((list) => (
        <NavList
          key={list?.title}
          data={list}
          render={render}
          depth={1}
          slotProps={slotProps}
          enabledRootRedirect={enabledRootRedirect}
        />
      ))}
    </NavUl>
  );

  return (
    <NavLi>
      {subheader ? (
        <>
          <Subheader
            data-title={subheader}
            open={open}
            onClick={handleToggle}
            sx={slotProps?.subheader}
          >
            {subheader}
          </Subheader>

          <Collapse in={open}>{renderContent}</Collapse>
        </>
      ) : (
        renderContent
      )}
    </NavLi>
  );
}
