import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const UpdateIOStatusURL = '/api/v1/internal-service/io/update-io-status';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const updateIOStatus = createAsyncThunk(
  'updateIOStatus',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(UpdateIOStatusURL, request)
      .then((response) => {
        return response?.data?.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const UpdateIOStatus = createSlice({
  name: 'UpdateIOStatus',
  initialState,
  reducers: {
    clearUpdateIOStatusState: (state) => {
      state.status = 'idle';
      state.response = null;
      state.error = null;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(updateIOStatus.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(updateIOStatus.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload;
      })
      .addCase(updateIOStatus.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const updateIOStatusState = (state) => state.UpdateIOStatus;
export default UpdateIOStatus.reducer;
export const { clearUpdateIOStatusState } = UpdateIOStatus.actions;
