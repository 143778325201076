import { notification } from 'antd';
import axios, { AxiosError, AxiosInstance, InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import { datadogLogs } from '@datadog/browser-logs';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const AUTH_URL = '/api/v1/internal-service/auth/google/login';
const AUTH_URL_2FA = '/api/v1/internal-service/auth/2fa/validate';
const ENABLE_2FA = '/api/v1/internal-service/auth/2fa/enable';
const ENABLE_2FA_VERIFY = '/api/v1/internal-service/auth/2fa/verify';
const NORMAL_LOGIN_FLOW_URL = '/api/v1/internal-service/auth/sign-in';
const JWT_EXPIRED = 'jwt expired';
const UNAUTHORIZED = 'Unauthorized';
const FORBIDDEN = 401;

const axiosInstance: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

const logTrigger = (message, jsonAttributes, status, error = null) => {
  datadogLogs.logger.log(message, jsonAttributes, status, error);
};

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    // add auth header with jwt if account is logged in and request is to the api url
    if (
      localStorage.getItem('JWT_TOKEN') &&
      (!config.url.includes(AUTH_URL) || !config.url.includes(NORMAL_LOGIN_FLOW_URL))
    ) {
      config.headers['x-vi-internal-authorization'] = localStorage.getItem('JWT_TOKEN');
    } else if (
      localStorage.getItem('token2fa') &&
      (config.url.includes(ENABLE_2FA) || config.url.includes(ENABLE_2FA_VERIFY))
    ) {
      config.headers['x-vi-internal-authorization'] = localStorage.getItem('token2fa');
    }
    return config;
  },
  (error) => {
    // Check if it's a network error
    if (axios.isAxiosError(error) && !error.response) {
      // Handle network error
      console.error(error);
    } else {
      console.error(error);
      // Handle other types of errors
    }
    return Promise.reject(error);
  },
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    if (
      ((response.config.url.includes(AUTH_URL) ||
        response.config.url.includes(NORMAL_LOGIN_FLOW_URL)) &&
        response.data?.data?.payload?.is_2fa_required === 0) ||
      (response.config.url.includes(AUTH_URL_2FA) &&
        response.data?.data?.payload?.is_2fa_enabled === 1)
    ) {
      localStorage.setItem('JWT_TOKEN', response.data?.data?.payload?.token);
      localStorage.setItem('EMPLOYEE_ID', response.data?.data?.payload?.employee_id);
      localStorage.setItem('USER_GROUPS', JSON.stringify(response.data?.data?.payload?.groups));
    }
    return response;
  },
  (error: AxiosError | Error): Promise<AxiosError> => {
    // special case if the user is login and we push the code for 2FA
    const errCode = axios.isAxiosError(error) && error?.response?.data?.status;
    const errMsg = axios.isAxiosError(error) && error?.response?.data?.data?.message;
    const errorConfig = axios.isAxiosError(error) && error?.response?.data;

    logTrigger(errMsg, { errorConfig, errorCode: errCode }, 'error', error);

    if (errCode === 403 && errMsg === 'Please set up 2FA authentication before proceeding.') {
      notification.error({
        message: 'Error',
        description: 'Please refresh and login again',
        duration: 5,
        placement: 'topRight',
      });

      localStorage.clear();
    }
    if (
      (axios.isAxiosError(error) && error?.response?.data?.data?.message === UNAUTHORIZED) ||
      (axios.isAxiosError(error) && error?.response?.status === FORBIDDEN) ||
      (axios.isAxiosError(error) && error?.response?.data?.data?.message === JWT_EXPIRED)
    ) {
      localStorage.removeItem('JWT_TOKEN');
      localStorage.removeItem('USER_GROUPS');
      localStorage.removeItem('EMPLOYEE_ID');
      localStorage.removeItem('token2fa');

      // Added timeout so that user can see error(if any) before redirection
      setTimeout(() => {
        window.location.replace('/sign-in');
      }, 1000);
    }

    return Promise.reject(error);
  },
);

export const axiosBaseQuery = async (arg) => {
  try {
    const response = await axiosInstance.request(arg);
    return { data: response?.data };
  } catch (error) {
    // const axiosError = error as AxiosError;
    // if (axiosError.response && axiosError.response.status === FORBIDDEN) {
    //   if (axiosError.response.data.message === JWT_EXPIRED) {
    //     // Handle JWT expiration (e.g., redirect to login, refresh token)
    //     // notification.error('Your session has expired! Please log in again.');
    //   } else if (axiosError.response.data.message === UNAUTHORIZED) {
    //     // Handle unauthorized access (e.g., redirect to login)
    //     // notification.error('You are not authorized to access this resource.');
    //   } else {
    //     // Handle other 401 errors
    //     console.error('Unauthorized access:', axiosError.response.data);
    //   }
    // } else {
    // Handle other errors
    console.error('Error fetching data:', error);
    // }
    return { error: error?.response?.data?.data };
  }
};
