import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const revertCandidatureConfirmationUrl = '/api/v1/internal-service/candidature';

export const RevertCandidatureConfirmationApi = createApi({
  reducerPath: 'revertCandidatureConfirmationApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    revertConfirmation: builder.mutation({
      query: (candidatureId) => {
        let url = `${revertCandidatureConfirmationUrl}/${candidatureId}/revert`;
        return {
          url,
          method: 'PATCH',
          body: null,
        };
      },
    }),
  }),
});

export const { useRevertConfirmationMutation } = RevertCandidatureConfirmationApi;
export default RevertCandidatureConfirmationApi.reducer;
