import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../Axios/AxiosBaseQuery';

const blacklistCompanyUrl = '/api/v1/internal-service/host-company';
const blacklistReasonsUrl = '/api/v1/internal-service/common/blacklist-reasons';
const companyActiveInteractionsUrl = '/api/v1/internal-service/host-company';

export const BlacklistCompanyApi = createApi({
  reducerPath: 'BlacklistCompanyApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    blacklistCompany: builder.mutation({
      query: ({ hcId, payload }) => {
        return { url: `${blacklistCompanyUrl}/${hcId}/blacklist`, method: 'PATCH', body: payload };
      },
    }),
    whitelistCompany: builder.mutation({
      query: ({ hcId }) => {
        return { url: `${blacklistCompanyUrl}/${hcId}/whitelist`, method: 'PATCH', body: null };
      },
    }),
    blacklistReasons: builder.query({
      query: () => {
        return { url: blacklistReasonsUrl, method: 'GET', body: null };
      },
      transformResponse: (response) => {
        return response?.payload?.map((item) => ({
          label: item?.blacklist_reason,
          value: item?.blacklist_reason,
        }));
      },
    }),
    companyActiveInteractions: builder.query({
      query: (hcId) => {
        return {
          url: `${companyActiveInteractionsUrl}/${hcId}/active-interactions`,
          method: 'GET',
          body: null,
        };
      },
      transformResponse: (response) => {
        return response?.payload;
      },
    }),
  }),
});

export const {
  useBlacklistCompanyMutation,
  useWhitelistCompanyMutation,
  useBlacklistReasonsQuery,
  useCompanyActiveInteractionsQuery,
} = BlacklistCompanyApi;
export default BlacklistCompanyApi.reducer;
