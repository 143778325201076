import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const sendReminderToSelectInterviewSlotURL = '/api/v1/internal-service/intern/reminder-select-interview-time-slot';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const sendReminderToSelectInterviewSlot = createAsyncThunk('sendReminderToSelectInterviewSlot', async (request, { rejectWithValue }) => {
  const result = await axios
    .post(sendReminderToSelectInterviewSlotURL, request)
    .then((response) => {
      return response?.data?.data;
    })
    .catch((error) => {
      throw rejectWithValue(error);
    });
  return result;
});

const SendReminderToSelectInterviewSlot = createSlice({
  name: 'SendReminderToSelectInterviewSlot',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(sendReminderToSelectInterviewSlot.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(sendReminderToSelectInterviewSlot.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload;
      })
      .addCase(sendReminderToSelectInterviewSlot.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const sendReminderToSelectInterviewSlotState = (state) => state.SendReminderToSelectInterviewSlot;
export default SendReminderToSelectInterviewSlot.reducer;
