const reduxLogger = require('redux-logger');

import { GetApprovedHostCompanyToRecommendApi } from '../Slices/Intern/GetApprovedHostCompanyToRecommend';
import { RecommendInternsToHCApi } from '../Slices/Intern/RecommendInternsToHC';
import { GetManualRecommendedProfilesActiveApi } from '../Slices/HostCompany/GetManualRecommendedProfilesActive';
import { GetManualRecommendedProfilesPastApi } from '../Slices/HostCompany/GetManualRecommendedProfilesPast';
import { BatchNotesApi } from '../Slices/Notes/BatchNotes';
import { CheckIfInternCanRecommendApi } from '../Slices/Intern/CheckIfInternCanRecommend';
import { GetUniversityApi } from '../Slices/Intern/GetUniversity';
import { GetBatchDetailApi } from '../Slices/Intern/GetBatchDetail';
import { InternshipOpportunityApi } from '../Slices/InternshipRequirement/InternshipRequirement';
import { GetCandidatureInfoApi } from '../Slices/Intern/GetCandidatureInfo';
import { OffersApi } from '../Slices/Offer/OffersApi';
import { GetBatchListApi } from '../Slices/Batch/GetBatchListRTK';
import { GetWeeklyFeedbackApi } from '../Slices/Intern/GetWeeklyFeedback';
import { GetWeeklyInternDetailsApi } from '../Slices/Intern/GetWeeklyInternDetails';
import { GetWeeklyFeedbackByIdApi } from '../Slices/Intern/GetWeeklyFeedbackById';
import { GetWeeklyShortcutsApi } from '../Slices/Intern/GetWeeklyShortcuts';
import { CommonListApis } from '../Slices/Common/GetListApis';
import { GetAdditionalInfo } from '../Slices/HostCompany/GetAdditionalInfo';
import { GetBasicDetails } from '../Slices/HostCompany/GetBasicDetails';
import { GetHCPocs } from '../Slices/HostCompany/GetHCPocs';
import { UpdateHCDetails } from '../Slices/HostCompany/UpdateHCDetails';
import { UpdatePocDetails } from '../Slices/HostCompany/UpdatePocDetails';
import { GetSocialMediaDetails } from '../Slices/HostCompany/GetSocialMediaDetails';
import { DeleteHcPhoto } from '../Slices/HostCompany/DeleteHcPhoto';
import { AuthApi } from '../Slices/Auth/AuthApi';
import { RevertOfferAcceptanceApi } from '../Slices/Offer/RevertAcceptance';
import { ManageInternshipsApi } from '../Slices/Intern/ManageInternships';
import { RevertCandidatureConfirmationApi } from '../Slices/Intern/RevertRejection';
import { RevertConfirmationApi } from '../Slices/Interview/RevertConfirmation';
import { ManageVideoApplicationApi } from '../Slices/ManageApplication/manageVideoApplication';
import { PostCustomMailApi } from '../Slices/ManageApplication/postCustomMail';
import { GetApplicationPAMApi } from '../Slices/Intern/GetApplicationPAM';
import { GetBatchCodeListApi } from '../Slices/Batch/GetBatchCodeListRTK';
import { GetInternProfileDetailsApi } from '../Slices/Intern/GetInternProfileDetails';
import { GetFullInternListApi } from '../Slices/Intern/GetFullInternList';
import { GetApplicationListPAMApi } from '../Slices/PAM/GetApplicationListPAM';
import { ManagePlacementsApi } from '../Slices/HostCompany/ManagePlacements/ManagePlacementsApi';
import { BlacklistCompanyApi } from '../Slices/HostCompany/BlacklistCompany/BlacklistCompanyApi';
import { ShortlistedHcsApi } from '../Slices/HostCompany/ManagePlacements/ShortListedHcApi';
import { GetHcDetailsByIdOrEmailApi } from '../Slices/Interview/GetHcDetailsByIdOrEmailApi';
import { GetAnalyticsDetailsApi } from '../Slices/Dashboard/Analytics';
import { GetInterviewDetailsApi } from '../Slices/Interview/GetInterviewsList';
import { HostCompanyTableApi } from '../Slices/HostCompany/RtkQuery/HostCompanyDetailsApi';
import { GetInternDetailsByEmailApi } from '../Slices/Interview/GetInternDetailsByEmailIdRTK';
import { ManageRecommendationsApi } from '../Slices/HostCompany/ManageRecommendations';
import { AssignAEApi } from '../Slices/HostCompany/AssignAE';
import { AssignCamApi } from '../Slices/HostCompany/RtkQuery/AssignCam';
import { CreateCompanyApi } from '../Slices/HostCompany/CreateCompany';
import { GetCountByStartDateApi } from '../Slices/Batch/GetCountByStartDate';
import { MarkCompanySignupComplete } from '../Slices/HostCompany/MarkCompanySignupComplete';
import { GetOfferListApi } from '../Slices/Offer/GetOfferListApi';
import { ManageVideosApi } from '../Slices/ManageApplication/getManageVideoApplicationlist';
import { ManagePartnerList } from '../Slices/Partner/GetPartnerList';
import { ManageNotesApi } from '../Slices/GlobalNotes/ManageNotes';
import { GetProfileApi } from '../Slices/User/GetUserProfileNew';
import { ManageBatchApi } from '../Slices/BatchRtk/ManageBatch';

const logger = reduxLogger.createLogger();

export const rootMiddleware = [
  GetApprovedHostCompanyToRecommendApi.middleware,
  RecommendInternsToHCApi.middleware,
  CheckIfInternCanRecommendApi.middleware,
  GetManualRecommendedProfilesActiveApi.middleware,
  GetManualRecommendedProfilesPastApi.middleware,
  BatchNotesApi.middleware,
  GetUniversityApi.middleware,
  GetBatchDetailApi.middleware,
  InternshipOpportunityApi.middleware,
  GetCandidatureInfoApi.middleware,
  OffersApi.middleware,
  GetBatchListApi.middleware,
  GetWeeklyFeedbackApi.middleware,
  GetWeeklyInternDetailsApi.middleware,
  GetWeeklyFeedbackByIdApi.middleware,
  GetWeeklyShortcutsApi.middleware,
  CommonListApis.middleware,
  GetAdditionalInfo.middleware,
  GetBasicDetails.middleware,
  GetHCPocs.middleware,
  UpdateHCDetails.middleware,
  UpdatePocDetails.middleware,
  GetSocialMediaDetails.middleware,
  RevertConfirmationApi.middleware,
  DeleteHcPhoto.middleware,
  AuthApi.middleware,
  RevertOfferAcceptanceApi.middleware,
  ManageInternshipsApi.middleware,
  RevertCandidatureConfirmationApi.middleware,
  ManageVideoApplicationApi.middleware,
  PostCustomMailApi.middleware,
  GetApplicationPAMApi.middleware,
  GetBatchCodeListApi.middleware,
  GetInternProfileDetailsApi.middleware,
  GetFullInternListApi.middleware,
  GetApplicationListPAMApi.middleware,
  ManagePlacementsApi.middleware,
  BlacklistCompanyApi.middleware,
  ShortlistedHcsApi.middleware,
  GetHcDetailsByIdOrEmailApi.middleware,
  GetAnalyticsDetailsApi.middleware,
  GetInterviewDetailsApi.middleware,
  GetInternDetailsByEmailApi.middleware,
  HostCompanyTableApi.middleware,
  ManageRecommendationsApi.middleware,
  GetOfferListApi.middleware,
  AssignAEApi.middleware,
  AssignCamApi.middleware,
  CreateCompanyApi.middleware,
  GetCountByStartDateApi.middleware,
  MarkCompanySignupComplete.middleware,
  ManageVideosApi.middleware,
  ManagePartnerList.middleware,
  ManageNotesApi.middleware,
  GetProfileApi.middleware,
  ManageBatchApi.middleware,
];

if (process.env.REACT_APP_CLUSTER === 'local') {
  rootMiddleware.push(logger);
}
