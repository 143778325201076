import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';
import { getParamObjectModifier } from '../../../Util/utility';
import { onQueryStartedErrorToast } from 'Util/rtkQueryErrorHandler';

const getInternProfileDetailsURL =
  '/api/v1/internal-service/intern-application/get-intern-profile-detail';
const saveDraftFeedbackURL = '/api/v1/internal-service/intern/profile/draft-feedback';
const sectionFeedbackURL = '/api/v1/internal-service/intern/profile';
const getInternProfileRatingURL = '/api/v1/internal-service/intern/profile-rating';
const getFullInternListURL = '/api/v1/internal-service/intern';

export const GetInternProfileDetailsApi = createApi({
  reducerPath: 'getInternProfileDetailsApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['inetrn-list', 'candidates-profile', 'section-feedback', 'profile-rating'],
  endpoints: (builder) => ({
    getFullInternListApi: builder.query({
      query: (data) => {
        const modifiedParam = getParamObjectModifier(data);
        return { url: getFullInternListURL, method: 'GET', body: modifiedParam };
      },
      providesTags: ['inetrn-list'],
    }),
    getInternProfileDetailsApi: builder.query({
      query: (data) => ({ url: getInternProfileDetailsURL, method: 'POST', body: data }),
      providesTags: ['candidates-profile'],
    }),
    saveDraftFeedback: builder.mutation({
      query: (data) => ({ url: saveDraftFeedbackURL, method: 'POST', body: data }),
      invalidatesTags: (result, error) => (error ? [] : ['section-feedback', 'candidates-profile']),
    }),
    saveTalentInsight: builder.mutation({
      query: ({ internId, method = 'POST', ...data }) => ({
        url: `${sectionFeedbackURL}/${internId}/insight`,
        method: method,
        body: data,
      }),
      onQueryStarted: onQueryStartedErrorToast,
      invalidatesTags: (result, error) => (error ? [] : ['candidates-profile']),
    }),
    deleteTalentInsight: builder.mutation({
      query: ({ internId }) => ({
        url: `${sectionFeedbackURL}/${internId}/insight/delete`,
        method: 'DELETE',
      }),
      onQueryStarted: onQueryStartedErrorToast,
      invalidatesTags: (result, error) => (error ? [] : ['candidates-profile']),
    }),
    getSectionFeedback: builder.query({
      query: (data) => {
        return {
          url: `${sectionFeedbackURL}/${data?.intern_id}/feedback`,
          method: 'GET',
        };
      },
      providesTags: ['section-feedback'],
    }),
    saveInternProfileRating: builder.mutation({
      query: (data) => {
        return {
          url: getInternProfileRatingURL,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: (result, error) => (error ? [] : ['inetrn-list', 'profile-rating']),
    }),
    getInternProfileRating: builder.query({
      query: (data) => {
        return {
          url: `/api/v1/internal-service/intern/${data?.intern_id}/profile-rating`,
          method: 'GET',
        };
      },
      providesTags: ['profile-rating'],
    }),
    shareProfileFeedback: builder.mutation({
      query: (data) => {
        return {
          url: `${sectionFeedbackURL}/${data?.intern_id}/share-feedback`,
          method: 'PATCH',
        };
      },
      invalidatesTags: (result, error) =>
        error ? [] : ['inetrn-list', 'candidates-profile', 'section-feedback'],
    }),
    acceptProfileFeedback: builder.mutation({
      query: (data) => {
        return {
          url: `${sectionFeedbackURL}/${data?.intern_id}/accept`,
          method: 'PATCH',
        };
      },
      invalidatesTags: (result, error) =>
        error ? [] : ['inetrn-list', 'candidates-profile', 'section-feedback'],
    }),
    moveProfile: builder.mutation({
      query: (data) => {
        return {
          url: `${sectionFeedbackURL}/${data?.intern_id}/move-to-review`,
          method: 'PATCH',
        };
      },
      invalidatesTags: (result, error) => (error ? [] : ['inetrn-list', 'candidates-profile']),
    }),
    rejectPAM: builder.mutation({
      query: (body) => {
        return { url: '/api/v1/internal-service/pam/application/reject', method: 'PATCH', body };
      },
      invalidatesTags: (result, error) => (error ? [] : ['inetrn-list', 'candidates-profile']),
    }),
  }),
});

export const {
  useGetInternProfileDetailsApiQuery,
  useSaveDraftFeedbackMutation,
  useSaveTalentInsightMutation,
  useDeleteTalentInsightMutation,
  useGetSectionFeedbackQuery,
  useSaveInternProfileRatingMutation,
  useGetInternProfileRatingQuery,
  useShareProfileFeedbackMutation,
  useAcceptProfileFeedbackMutation,
  useMoveProfileMutation,
  useRejectPAMMutation,
  useGetFullInternListApiQuery,
  usePrefetch,
} = GetInternProfileDetailsApi;

export default GetInternProfileDetailsApi.reducer;
