import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const inviteUserURL = '/api/v1/internal-service/auth/invite';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const inviteUser = createAsyncThunk('inviteUser', async (request, { rejectWithValue }) => {
  const result = await axios
    .post(inviteUserURL, request)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw rejectWithValue(error);
    });
  return result;
});

const InviteUserSlice = createSlice({
  name: 'Invite',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate invite user state handling*/
      .addCase(inviteUser.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(inviteUser.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload;
      })
      .addCase(inviteUser.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getInviteUserState = (state) => state.UserInvite;
export default InviteUserSlice.reducer;
