import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const url = '/api/v1/internal-service/offers/get';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
  isLoading: false,
};

export const getOfferById = createAsyncThunk(
  'getOfferById',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .get(url + `/${request}`)
      .then((response) => {
        return response?.data?.data?.payload;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetOfferById = createSlice({
  name: 'GetOfferById',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getOfferById.pending, (state) => {
        state.status = 'Pending';
        state.isLoading = true;
      })
      .addCase(getOfferById.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload;
        state.isLoading = false;
      })
      .addCase(getOfferById.rejected, (state) => {
        state.status = 'Failed';
        state.isLoading = false;
      });
  },
});

export const getOfferByIdState = (state) => state.GetOfferById;
export default GetOfferById.reducer;
