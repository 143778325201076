import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

export const UpdateHCDetails = createApi({
  reducerPath: 'updateHCDetails',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    saveHCDetails: builder.mutation({
      query: (data) => {
        const hcId = data?.host_company_id;
        delete data?.host_company_id;
        return {
          url: 'api/v1/internal-service/host-company/' + parseInt(hcId),
          method: 'PATCH',
          body: data,
        };
      },
    }),
  }),
});

export const { useSaveHCDetailsMutation } = UpdateHCDetails;
