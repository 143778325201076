import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const markSignupCompleteUrl = 'api/v1/internal-service/host-company';
export const MarkCompanySignupComplete = createApi({
  reducerPath: 'markCompanySignupComplete',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    markCompanySignupComplete: builder.mutation({
      query: (data) => {
        const hcId = parseInt(data?.host_company_id);
        delete data?.host_company_id;
        return {
          url: `${markSignupCompleteUrl}/${hcId}/mark-signup-complete`,
          method: 'PATCH',
          body: data,
        };
      },
    }),

    getCompanyDetailForMarkSignupComplete: builder.query({
      query: ({ hcId }) => {
        const id = parseInt(hcId);
        return {
          url: `${markSignupCompleteUrl}/${id}/mark-signup-complete`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useMarkCompanySignupCompleteMutation,
  useGetCompanyDetailForMarkSignupCompleteQuery,
} = MarkCompanySignupComplete;
