import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const createUserGroupURL = '/api/v1/internal-service/group/create';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const createUserGroup = createAsyncThunk(
  'createUserGroup',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(createUserGroupURL, request)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const CreateUserGroupSlice = createSlice({
  name: 'CreateUserGroup',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate create user group state handling*/
      .addCase(createUserGroup.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(createUserGroup.fulfilled, (state, action) => {
        state.status = action.payload.data.isSuccess ? 'Success' : 'Failed';
        state.response = action?.payload?.data?.payload || action?.payload?.data?.message;
      })
      .addCase(createUserGroup.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getCreateUserGroupState = (state) => state.CreateUserGroup;
export default CreateUserGroupSlice.reducer;
