import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { showSnackbarState, hideSnackbar } from '../../../Redux/Slices/Common/SnackbarSlice';

type Severity = 'error' | 'warning' | 'info' | 'success';

interface SnackbarConfig {
  severity: Severity;
  alertTitle: string;
  message: string;
  open: boolean;
}

const SnackbarAlert: React.FC = () => {
  const { open, severity, alertTitle, message }: SnackbarConfig = useSelector(showSnackbarState);
  const dispatch = useDispatch();

  const handleHideSnackbar = () => {
    dispatch(hideSnackbar());
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleHideSnackbar}
    >
      <Alert
        severity={severity}
        action={
          <IconButton size='small' color='inherit' onClick={handleHideSnackbar}>
            <CloseIcon />
          </IconButton>
        }
      >
        {alertTitle && <AlertTitle>{alertTitle}</AlertTitle>}
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarAlert;
