import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const GetCompanyPOCsURL = '/api/v1/internal-service/host-company/get-alternatePoc-detail';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
  isLoading: false,
};
export const getCompanyPOCs = createAsyncThunk(
  'getCompanyPOCs',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(GetCompanyPOCsURL, request)
      .then((response) => {
        return response?.data?.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetCompanyPOCs = createSlice({
  name: 'GetCompanyPOCs',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCompanyPOCs.pending, (state) => {
        state.status = 'Pending';
        state.isLoading = true;
      })
      .addCase(getCompanyPOCs.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload;
        state.isLoading = false;
      })
      .addCase(getCompanyPOCs.rejected, (state) => {
        state.status = 'Failed';
        state.isLoading = false;
      });
  },
});

export const getCompanyPOCsState = (state) => state.GetCompanyPOCs;
export default GetCompanyPOCs.reducer;
