/* A redux-toolkit slice. */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const updateBatchSignUpURL = '/api/v1/internal-service/batch/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const updateBatchSignUp = createAsyncThunk(
  'updateBatchSignUp',
  async (request, { rejectWithValue, fulfillWithValue }) => {
    try {
      const batchId = request?.batch_id;

      // delete changedValues.batch_id;
      delete request['batch_id'];
      delete request['changedValues'];

      // updating batch step one
      const result = await axios.post(updateBatchSignUpURL + batchId + '/signup-settings', request);

      // create system logs for batch step one

      return fulfillWithValue(result?.data?.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const UpdateBatchSignUpSlice = createSlice({
  name: 'UpdateBatchSignUp',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate update batch state handling*/
      .addCase(updateBatchSignUp.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(updateBatchSignUp.fulfilled, (state, action) => {
        state.status = action?.payload?.isSuccess ? 'Success' : 'Failed';
        state.response = action?.payload?.payload;
      })
      .addCase(updateBatchSignUp.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getUpdateBatchSignUpState = (state) => state.UpdateBatchSignUp;
export default UpdateBatchSignUpSlice.reducer;
