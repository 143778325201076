import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const getInternsListByAasURL = '/api/v1/internal-service/intern/list-interns-by-aa';

const initialState = {
  status: 'idle',
  isLoading: false,
  error: null,
  response: null,
  count: 0,
};
export const getInternsListByAas = createAsyncThunk(
  'getInternsListByAas',
  async (request, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await axios.post(getInternsListByAasURL, request);
      if (result?.data?.data?.isSuccess) {
        return fulfillWithValue(result?.data?.data);
      }
    } catch (error) {
        return rejectWithValue(error);
    }
  },
);

const GetInternsListByAasSlice = createSlice({
  name: 'getInternsListByAas',
  initialState,
  extraReducers: {
    [getInternsListByAas.pending]: (state) => {
      state.status = 'Pending';
      state.isLoading = true;
      state.count = 0;
    },
    [getInternsListByAas.fulfilled]: (state, action) => {
      state.status = 'Success';
      state.response = action?.payload?.payload;
      state.isLoading = false;
      state.count = action?.payload?.count;
    },
    [getInternsListByAas.rejected]: (state, error) => {
      state.status = 'Failed';
      state.error = error;
      state.isLoading = false;
      state.count = 0;
    },
  },
});

export const getInternsListByAasState = (state) => state.GetInternsListByAas;
export default GetInternsListByAasSlice.reducer;
