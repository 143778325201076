import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';
import { getParamObjectModifier } from '../../../Util/utility';

const url = '/api/v1/internal-service/partner';

export const ManagePartnerList = createApi({
  reducerPath: 'ManagePartnerList',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getPartnerList: builder.query({
      query: (request = {}) => {
        const modifiedParam = getParamObjectModifier(request);

        return {
          url,
          method: 'GET',
          body: modifiedParam,
        };
      },
    }),
  }),
});

export const getFormattedPartnerData = (list = []) => {
  if (list?.length) {
    return list?.map((item) => ({
      label: item.partner_name,
      value: item.partner_id,
    }));
  } else return [];
};

export const { useGetPartnerListQuery, usePrefetch } = ManagePartnerList;
export default ManagePartnerList.reducer;
