import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const remindInternURL = '/api/v1/internal-service/intern/reminder-profile-completion';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
  isLoading: false,
};

export const remindIntern = createAsyncThunk(
  'remindIntern',
  async (request, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await axios.post(remindInternURL, request);
      if (result?.data?.data?.isSuccess) {
        return fulfillWithValue(result?.data?.data);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const remindInternSlice = createSlice({
  name: 'remindIntern',
  initialState,
  reducers: {
    remindInternStateReset: (state) => {
      state.status = 'idle';
      state.error = null;
      state.response = null;
      state.isLoading = false;
    },
  },
  extraReducers: {
    [remindIntern.pending]: (state) => {
      state.status = 'Pending';
      state.isLoading = true;
    },
    [remindIntern.fulfilled]: (state, action) => {
      state.status = 'Success';
      state.response = action?.payload;
      state.isLoading = false;
    },
    [remindIntern.rejected]: (state, error) => {
      state.status = 'Failed';
      state.error = error;
      state.isLoading = false;
    },
  },
});

export const remindInternState = (state) => state.RemindIntern;
export const { remindInternStateReset } = remindInternSlice.actions;
export default remindInternSlice.reducer;