import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const createGlobalSettingURL = '/api/v1/internal-service/global-setting/create';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const createGlobalSetting = createAsyncThunk(
  'createGlobalSettingURL',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(createGlobalSettingURL, request)
      .then((response) => {
        return response?.data?.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const CreateGlobalSettingSlice = createSlice({
  name: 'CreateGlobalSetting',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate career fields status state handling*/
      .addCase(createGlobalSetting.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(createGlobalSetting.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.payload;
      })
      .addCase(createGlobalSetting.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const CreateGlobalSettingSliceState = (state) => state.CreateGlobalSetting;
export default CreateGlobalSettingSlice.reducer;
