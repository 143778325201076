import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const bulkAssignPMURL = '/api/v1/internal-service/io/bulk-assign-pm';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const bulkAssignPM = createAsyncThunk(
  'bulkAssignPM',
  async (request, { rejectWithValue }) => {
    try {
      const result = await axios
        .post(bulkAssignPMURL, request)
        .then((response) => {
          return response?.data?.data;
        })
        .catch((error) => {
          throw rejectWithValue(error);
        });
      return result;
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);

const BulkAssignPM = createSlice({
  name: 'BulkAssignPM',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate assignIEMBulk state handling*/
      .addCase(bulkAssignPM.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(bulkAssignPM.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload;
      })
      .addCase(bulkAssignPM.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const bulkAssignPMState = (state) => state.BulkAssignPM;
export default BulkAssignPM.reducer;
