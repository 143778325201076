import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const getBatchCodeListURL = '/api/v1/internal-service/batch/get';

export const GetBatchCodeListApi = createApi({
  reducerPath: 'batchCodeListApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    batchCodeListApi: builder.query({
      query: () => ({ url: getBatchCodeListURL, method: 'GET' }),
      transformResponse: (res, meta, arg) => {
        console.log(res, meta, arg, 'qry,meta,arg');
        if (arg?.format) {
          return res?.payload?.map((batch) => ({ label: batch.batch_code, value: batch.batch_id }));
        } else {
          return res;
        }
      },
    }),
  }),
});

export const { useBatchCodeListApiQuery } = GetBatchCodeListApi;

export default GetBatchCodeListApi.reducer;
