export const NOTES_CARD_MENU_OPTIONS = ['Edit', 'Delete'];

export const TAG_TYPE = {
  INTERN: 'INTERN',
  HOST_COMPANY: 'HOST_COMPANY',
  PROCESS: 'PROCESS',
} as const;

export const TAG_TYPE_VS_ICONS = {
  INTERN: 'notes_intern2',
  HOST_COMPANY: 'notes_company',
  PROCESS: 'notes_label',
};

export const PROCESS_VS_API_DATA = {
  INTERN_DETAIL: 'Intern detail',
  REVIEW_PROFILE: 'Review profile',
  REVIEW_VIDEOS: 'Review videos',
  APPLICATION: 'Application',
  INTERNSHIP: 'Internship',
  INTERNSHIP_REQUIREMENT: 'Internship requirement',
  INTERVIEW: 'Interview',
  OFFER: 'Offer',
  HOST_COMPANY_DETAIL: 'Host company detail',
  WF_INTERN: 'WF (Intern)',
  WF_HC: 'WF (HC)',
  PLACEMENT: 'Placement',
  RECOMMENDATION: 'Recommendation',
};

export const KEY_MAP = {
  OPEN_MODAL: 'ctrl+n',
  CLOSE_MODAL: 'esc',
};

export const API_FILTER_VS_FILTERS = {
  companyData: 'host_company_ids',
  internData: 'interns_ids',
  processData: 'process_ids',
  userData: 'user_ids',
};

export const NOTES_FILTERS_INITIAL_STATE = {
  user_ids: [],
  host_company_ids: [],
  interns_ids: [],
  process_ids: [],
};

export const NOTES_DISPATCHER_TYPE = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
} as const;
