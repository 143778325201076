import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const getWeeklyFeedbackByIdURL = '/api/v1/internal-service/weekly-feedback-loop/internships/';
const updateWeeklyFeedbackDeadLineUrl = '/api/v1/internal-service/weekly-feedback-loop';

export const GetWeeklyFeedbackByIdApi = createApi({
  reducerPath: 'getWeeklyFeedbackByIdApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['getWeeklyFeedbackById'],
  endpoints: (builder) => ({
    getWeeklyFeedbackByIdApi: builder.query({
      query: (data) => ({
        url: getWeeklyFeedbackByIdURL + data?.internship_id,
        method: 'POST',
        body: { weekly_number: data?.weekly_number },
      }),
      providesTags: ['getWeeklyFeedbackById'],
    }),
    updateWeeklyFeedbackDeadline: builder.mutation({
      query: ({ selectedFeedbackId, data }) => ({
        url: `${updateWeeklyFeedbackDeadLineUrl}/${selectedFeedbackId}/deadline`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['getWeeklyFeedbackById'],
    }),
  }),
});

export const { useGetWeeklyFeedbackByIdApiQuery, useUpdateWeeklyFeedbackDeadlineMutation } =
  GetWeeklyFeedbackByIdApi;

export default GetWeeklyFeedbackByIdApi.reducer;
