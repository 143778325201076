import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const getGroupByIDURL = '/api/v1/internal-service/group/get/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
  count: 0,
};
export const getGroupByID = createAsyncThunk(
  'getGroupByID',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .get(getGroupByIDURL + request?.group_id)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetGroupByIDSlice = createSlice({
  name: 'GroupByID',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate get group by ID state handling*/
      .addCase(getGroupByID.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getGroupByID.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.data?.payload;
      })
      .addCase(getGroupByID.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getGroupByIDState = (state) => state.GroupByID;
export default GetGroupByIDSlice.reducer;
