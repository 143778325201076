import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';
import { createSystemLogs } from '../Common/CreateSystemLogs';

const updateBatchStatusURL = '/api/v1/internal-service/batch/update-status';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const updateBatchStatus = createAsyncThunk(
  'updateBatchStatusURL',
  async (request, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const result = await axios.post(updateBatchStatusURL, request);
      const batchInfo = {
        feature_type_id: request?.batch_id,
        feature_type: 'BATCH',
        action: 'Update',
        column_name: 'Batch status',
        from_value: request?.batch_status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
        to_value: request?.batch_status,
      };
      if (result?.data?.data?.isSuccess) {
        dispatch(createSystemLogs(batchInfo));
      }
      return fulfillWithValue(result?.data?.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const UpdateBatchStatusSlice = createSlice({
  name: 'BatchStatus',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate update batch status state handling*/
      .addCase(updateBatchStatus.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(updateBatchStatus.fulfilled, (state, action) => {
        if (action?.payload?.isSuccess) {
          state.status = 'Success';
          state.response = action?.payload;
        } else {
          state.status = 'Failed';
          state.message = action?.payload?.message;
        }
      })
      .addCase(updateBatchStatus.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getUpdateBatchStatusState = (state) => state.BatchStatus;
export default UpdateBatchStatusSlice.reducer;
