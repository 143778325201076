import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const getUserGroupsURL = '/api/v1/internal-service/group/list';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
  count:0
};
export const getUserGroupList = createAsyncThunk(
  'getUserGroups',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(getUserGroupsURL, request)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetUserGroupsSlice = createSlice({
  name: 'UserGroupList',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate get all user list state handling*/
      .addCase(getUserGroupList.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getUserGroupList.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.data?.payload;
        state.count = action?.payload?.data?.count
      })
      .addCase(getUserGroupList.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getUserGroupListState = (state) => state.UserGroupList;
export default GetUserGroupsSlice.reducer;
