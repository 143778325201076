import { useState, useEffect } from 'react';

export function useTruncateText(originalText: string, maxChars: number): string {
  const [truncatedText, setTruncatedText] = useState(originalText);

  useEffect(() => {
    if (originalText?.length > maxChars) {
      setTruncatedText(originalText.substring(0, maxChars) + '...');
    } else {
      setTruncatedText(originalText);
    }
  }, [originalText, maxChars]);

  return truncatedText;
}
