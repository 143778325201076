import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';
import { createMassSystemLogs } from '../Common/CreateMassSystemLogs';

const updateBatchStepTwoURL = '/api/v1/internal-service/batch/update/step2';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const updateBatchStepTwo = createAsyncThunk(
  'updateBatchStepTwo',
  async (request, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    try {
      // grab the state from the store
      const {
        GetInternshipDurations: { response: InternshipDurations },
        GetCareerFields: { response: CareerFields },
        GetGenderFields: { response: GenderFields },
        GetInternshipLocations: { response: InternshipLocations },
      } = getState();

      const mapAndFill = (arr, propertyName) => {
        return arr.map((item) => {
          if (propertyName) {
            return {
              batch_id: request?.batch_id,
              [propertyName]: item,
            };
          } else {
            return {
              ...item,
              batch_id: request?.batch_id,
            };
          }
        });
      };

      const careerFields = mapAndFill(request?.career_fields, 'career_field_id');
      const genderId = mapAndFill(request?.gender_id, 'gender_id');
      const internshipDurations = mapAndFill(request?.internship_durations, 'duration');
      const internshipLocations = mapAndFill(request?.internship_locations);

      const batchInfo = [
        {
          feature_type_id: request?.batch_id,
          feature_type: 'BATCH',
          action: 'Update',
          column_name: 'Internship durations',
          from_value: JSON.stringify(InternshipDurations?.results),
          to_value: JSON.stringify(internshipDurations),
        },
        {
          feature_type_id: request?.batch_id,
          feature_type: 'BATCH',
          action: 'Update',
          column_name: 'Career fields',
          from_value: JSON.stringify(CareerFields?.results),
          to_value: JSON.stringify(careerFields),
        },
        {
          feature_type_id: request?.batch_id,
          feature_type: 'BATCH',
          action: 'Update',
          column_name: 'Gender fields',
          from_value: JSON.stringify(GenderFields?.results),
          to_value: JSON.stringify(genderId),
        },
        {
          feature_type_id: request?.batch_id,
          feature_type: 'BATCH',
          action: 'Update',
          column_name: 'Internship locations',
          from_value: JSON.stringify(InternshipLocations?.results),
          to_value: JSON.stringify(internshipLocations),
        },
      ];

      // api call for updating batch details
      const result = await axios.post(updateBatchStepTwoURL, request);

      // create system logs for batch step Two
      if (result?.data?.data?.isSuccess) {
        dispatch(createMassSystemLogs(batchInfo));
      }
      return fulfillWithValue(result?.data?.data);
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);

const UpdateBatchStepTwoSlice = createSlice({
  name: 'UpdateBatchStepTwo',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate update batch state handling*/
      .addCase(updateBatchStepTwo.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(updateBatchStepTwo.fulfilled, (state, action) => {
        state.status = action.payload.isSuccess ? 'Success' : 'Failed';
        state.response = action?.payload?.payload;

        if (!action.payload.isSuccess) {
          state.error = action?.payload?.message;
        }
      })
      .addCase(updateBatchStepTwo.rejected, (state, action) => {
        state.status = 'Failed';
        state.error = action?.payload?.payload;
      });
  },
});

export const getUpdateBatchStepTwoState = (state) => state.UpdateBatchStepTwo;
export default UpdateBatchStepTwoSlice.reducer;
