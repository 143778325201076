import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosInstance';

const baseUrl = 'api/v1/internal-service/host-company/detail';

export const GetHcDetailsByIdOrEmailApi = createApi({
  reducerPath: 'GetHcDetailsByIdOrEmailApi',
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getHcDetailsByIdOrEmail: builder.query({
      query: ({ hcId }) => {
        return { url: `${baseUrl}/${hcId}`, method: 'GET' };
      },
    }),
  }),
});

export const { useGetHcDetailsByIdOrEmailQuery } = GetHcDetailsByIdOrEmailApi;

export default GetHcDetailsByIdOrEmailApi.reducer;
