import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const BatchNotesURL = '/api/v1/internal-service/batch';

export const BatchNotesApi = createApi({
  reducerPath: 'CreateBatchNotesApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    createBatchNotes: builder.mutation({
      query: (data) => {
        const batchId = data?.batch_id;
        delete data.batch_id;
        return { url: `${BatchNotesURL}/${batchId}/note`, method: 'POST', body: data };
      },
    }),
    createAdditionalDetails: builder.mutation({
      query: (data) => {
        console.log(data, 'sdfs')
        const batchId = data?.batchId;
        delete data.batchId;
        return { url: `${BatchNotesURL}/${batchId}/additional_details`, method: 'PATCH', body: data };
      },
    }),

    getBatchNotes: builder.query({
      query: (batchId) => ({ url: `${BatchNotesURL}/${batchId}/notes`, method: 'GET' }),
    }),
  }),
});

export const { useCreateBatchNotesMutation, useCreateAdditionalDetailsMutation,  useGetBatchNotesQuery } = BatchNotesApi;
export default BatchNotesApi.reducer;
