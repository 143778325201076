import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';
import { configs } from '../../../Util/configs';

const BASE_URL = configs.REACT_APP_BASE_URL_OTHR;
const CreateMassSystemLogsURL = BASE_URL + '/common-services/systemlog/create-mass';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const createMassSystemLogs = createAsyncThunk(
  'createMassSystemLogs',
  async (request, { rejectWithValue, fulfillWithValue, getState }) => {
    try {
      const {
        UserProfile: {
          response: { payload },
        },
      } = getState();

      const employeeInfo = {
        employee_id: payload?.employee_id,
        employees_name: payload?.full_name,
      };

      const systemLogData = request?.map((item) => {
        return { ...item, ...employeeInfo };
      });

      const result = await axios.post(CreateMassSystemLogsURL, systemLogData);
      return fulfillWithValue(result.data?.data);
    } catch (error) {
      throw rejectWithValue(error?.message);
    }
  },
);

const CreateMassSystemLogsSlice = createSlice({
  name: 'CreateMassSystemLogs',
  initialState,
  extraReducers: {
    [createMassSystemLogs.pending]: (state) => {
      state.status = 'Pending';
      state.error = null;
    },
    [createMassSystemLogs.fulfilled]: (state, action) => {
      state.status = 'Success';
      state.response = action?.payload;
    },
    [createMassSystemLogs.rejected]: (state, action) => {
      state.status = 'Failed';
      state.error = action?.payload;
    },
  },
});

export const getCreateMassSystemLogsState = (state) => state.CreateMassSystemLogs;
export default CreateMassSystemLogsSlice.reducer;
