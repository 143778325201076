import intl from 'react-intl-universal';
import axios from 'axios';
import { configs } from './configs';

export const DEFAULT_LANG = 'en';
export const ALL_LOCALES = ['en', 'ar'];
const axiosObject = axios.create({
  baseURL: configs.FE_BASE_URL(),
});
export const loadLocales = () => {
  require('intl/locale-data/jsonp/en.js');
  let currentLocale = intl.determineLocale({
    urlLocaleKey: 'lang',
    cookieLocaleKey: 'lang',
  });
  currentLocale = 'en-US';
  const result = axiosObject
    .get(`locales/${currentLocale}.json`)
    .catch((err) => {
      console.log(err);
    })
    .then((res) => {
      return intl.init({
        currentLocale,
        locales: {
          [currentLocale]: res?.data,
        },
      });
    })
    .then(() => {
      return true;
    });

  return result;
};
