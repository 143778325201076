import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';
import { createMassSystemLogs } from '../Common/CreateMassSystemLogs';

const updatePartnerURL = '/api/v1/internal-service/partner/update/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

const compareObjects = (obj1, obj2) => {
  let changes = [];

  for (let key in obj1) {
    if (
      Object.prototype.hasOwnProperty.call(obj1, key) &&
      Object.prototype.hasOwnProperty.call(obj2, key)
    ) {
      let val1 = obj1[key];
      let val2 = obj2[key];

      if (Array.isArray(val1) && Array.isArray(val2)) {
        if (JSON.stringify(val1) !== JSON.stringify(val2)) {
          changes.push({
            changedPropertyName: key,
            changedPropertyBeforeValue: val1,
            changedPropertyAfterValue: val2,
          });
        }
      } else if (typeof val1 === 'object' && typeof val2 === 'object') {
        let subChanges = compareObjects(val1, val2);
        if (subChanges.length > 0) {
          subChanges.forEach((change) => {
            change.changedPropertyName = key + '.' + change.changedPropertyName;
            changes.push(change);
          });
        }
      } else if (val1 !== val2) {
        changes.push({
          changedPropertyName: key,
          changedPropertyBeforeValue: val1,
          changedPropertyAfterValue: val2,
        });
      }
    }
  }

  return changes;
};
const removePropertiesFromArray = (obj, propsToRemove) => {
  const newArray = obj.poc.map((obj) => {
    let newObj = {};
    for (let prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop) && !propsToRemove.includes(prop)) {
        newObj[prop] = obj[prop];
      }
    }
    return newObj;
  });

  const newObject = Object.keys(obj).reduce((object, key) => {
    if (key !== 'poc') {
      object[key] = obj[key];
    }
    return object;
  }, {});

  newObject['poc'] = newArray;
  return newObject;
};

export const updatePartner = createAsyncThunk(
  'updatePartner',
  async (request, { rejectWithValue, getState, dispatch }) => {
    try {
      // grab the state from the request
      const { PartnerByID } = getState();

      // removing unwanted properties
      const previousPartnerDetails = removePropertiesFromArray(PartnerByID?.response, [
        'created_on',
        'modified_on',
        'partner_id',
        'partner_poc_id',
      ]);

      const changedPartnerDetails = compareObjects(previousPartnerDetails, request);

      const partnerLogs = changedPartnerDetails?.map((item) => {
        return {
          feature_type_id: request?.partner_id,
          feature_type: 'PARTNER',
          action: 'Update',
          column_name: item?.changedPropertyName,
          from_value: Array.isArray(item?.changedPropertyBeforeValue)
            ? JSON.stringify(item?.changedPropertyBeforeValue)
            : item?.changedPropertyBeforeValue,
          to_value: Array.isArray(item?.changedPropertyAfterValue)
            ? JSON.stringify(item?.changedPropertyAfterValue)
            : item?.changedPropertyAfterValue,
        };
      });

      const partnerId = request?.partner_id;
      delete request['partner_id'];

      const result = await axios.post(updatePartnerURL + partnerId, request);

      // create system logs for Partner Update Details
      if (result?.data?.data?.isSuccess && partnerLogs?.length > 0) {
        dispatch(createMassSystemLogs(partnerLogs));
      }
       if(!result?.data?.data?.isSuccess){
        throw rejectWithValue(result)
       }
      return result?.data?.data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);

const UpdatePartnerSlice = createSlice({
  name: 'UpdatePartner',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate update partner state handling*/
      .addCase(updatePartner.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(updatePartner.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.payload;
      })
      .addCase(updatePartner.rejected, (state, action) => {
        state.status = 'Failed';
        state.response = action?.payload?.payload;

      });
  },
});

export const getUpdatePartnerState = (state) => state.UpdatePartner;
export default UpdatePartnerSlice.reducer;
