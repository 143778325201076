import { notification } from 'antd';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const hostCompanyTableListURL = '/api/v1/internal-service/host-company/get-company-detail';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
  count: 0,
};
export const getHostCompanyTableList = createAsyncThunk(
  'getHostCompanyTableList',
  async (request) => {
    const result = await axios
      .post(hostCompanyTableListURL, request)
      .then((response) => {
        if (response?.error) {
          throw response.error;
        }
        return response?.data?.data;
      })
      .catch((error) => {
        notification.error({
          message: 'Error',
          description: error.error?.message || error?.message || 'Something went wrong !',
          duration: 5,
          placement: 'topRight',
        });
      });
    return result;
  },
);

const GetHostCompanyTableList = createSlice({
  name: 'GetHostCompanyTableList',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate getIEM state handling*/
      .addCase(getHostCompanyTableList.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getHostCompanyTableList.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload;
        state.count = action?.payload?.count;
      })
      .addCase(getHostCompanyTableList.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getHostCompanyTableListState = (state) => state.GetHostCompanyTableList;
export default GetHostCompanyTableList.reducer;
