import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const getWeeklyShortcutsURL =
  '/api/v1/internal-service/weekly-feedback-loop/internships-shortcut-text';

export const GetWeeklyShortcutsApi = createApi({
  reducerPath: 'getWeeklyShortcutsApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getWeeklyShortcutsApi: builder.query({
      query: () => ({ url: getWeeklyShortcutsURL, method: 'GET' }),
    }),
  }),
});

export const { useGetWeeklyShortcutsApiQuery } = GetWeeklyShortcutsApi;

export default GetWeeklyShortcutsApi.reducer;
