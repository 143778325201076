import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';
import { createSystemLogs } from '../Common/CreateSystemLogs';

const UpdateHostCompanyStatusURL = '/api/v1/internal-service/host-company/toggle-status';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const updateHostCompanyStatus = createAsyncThunk(
  'updateHostCompanyStatus',
  async (request, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const hostCompanyInfo = {
        feature_type_id: request?.host_company_id,
        feature_type: 'COMPANY',
        action: 'Update',
        column_name: 'Host Company Details Status',
        from_value: request?.profileDetail?.status,
        to_value: request?.status,
      };
      delete request['profileDetail'];
      const result = await axios.post(UpdateHostCompanyStatusURL, request);
      if (result?.data?.data?.isSuccess) {
        dispatch(createSystemLogs(hostCompanyInfo));
      }
      return fulfillWithValue(result?.data?.data);
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);

const UpdateHostCompanyStatus = createSlice({
  name: 'UpdateHostCompanyStatus',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate getIEM state handling*/
      .addCase(updateHostCompanyStatus.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(updateHostCompanyStatus.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload;
      })
      .addCase(updateHostCompanyStatus.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const updateHostCompanyStatusState = (state) => state.UpdateHostCompanyStatus;
export default UpdateHostCompanyStatus.reducer;
