import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';
import { getFeedback } from './GetFeedback';
import { getOfferById } from './GetOfferById';

const url = '/api/v1/internal-service/offers/save-feedback';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const saveFeedback = createAsyncThunk(
  'saveFeedback',
  async (request, { fulfillWithValue, dispatch }) => {
    try {
      const response = await axios.post(url, request);
      if (response?.data?.status === 200) {
        dispatch(getFeedback(request?.offer_id));
        dispatch(getOfferById(request?.offer_id));
      }
      return fulfillWithValue(response?.data?.data?.payload);
    } catch (error) {
      console.error(error);
    }
  },
);

const SaveFeedback = createSlice({
  name: 'SaveFeedback',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(saveFeedback.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(saveFeedback.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload;
      })
      .addCase(saveFeedback.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const saveFeedbackState = (state) => state.SaveFeedback;
export default SaveFeedback.reducer;
