import { getParamObjectModifier } from '../../../Util/utility';

import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const url = '/api/v1/internal-service/intern/video';

export const ManageVideosApi = createApi({
  reducerPath: 'getManageVideoApplicationList',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getVideos: builder.query({
      query: (request = {}) => {
        const modifiedParam = getParamObjectModifier(request);
        return {
          url,
          method: 'GET',
          body: modifiedParam,
        };
      },
    }),
  }),
});

export const { useGetVideosQuery, usePrefetch } = ManageVideosApi;
export default ManageVideosApi.reducer;
