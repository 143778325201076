import Stack from '@mui/material/Stack';

import { NavList } from './NavList';
import { NavGroupProps, NavSectionProps } from 'Components/Navbar/types';
import { NavUl, NavLi } from '../styles';

export function NavSectionMini({ data, slotProps }: NavSectionProps) {
  return (
    <Stack component='nav' className='nav__section__mini'>
      <NavUl sx={{ flex: '1 1 auto', gap: 1 }}>
        {data?.map((group) => (
          <Group
            key={group?.subheader ?? group?.items?.[0]?.title}
            items={group?.items}
            slotProps={slotProps}
          />
        ))}
      </NavUl>
    </Stack>
  );
}

function Group({ items, slotProps }: NavGroupProps) {
  return (
    <NavLi>
      <NavUl sx={{ gap: 1 }}>
        {items?.map((list) => (
          <NavList key={list?.title} depth={1} data={list} slotProps={slotProps} />
        ))}
      </NavUl>
    </NavLi>
  );
}
