import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../Axios/AxiosInstance';

const assignCamURL = '/api/v1/internal-service/host-company/bulk-assign-cam';
const getCamListUrl = 'api/v1/internal-service/group/get/6/active';

export const AssignCamApi = createApi({
  reducerPath: 'AssignCamApi',
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getCamList: builder.query({
      query: () => ({
        url: getCamListUrl,
        method: 'GET',
      }),
    }),
    bulkAssignCam: builder.mutation({
      query: (data) => ({
        url: assignCamURL,
        method: 'POST',
        data,
      }),
    }),
  }),
});

export const { useGetCamListQuery, useBulkAssignCamMutation } = AssignCamApi;

export default AssignCamApi.reducer;
