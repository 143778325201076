import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const updateUserByIDURL = '/api/v1/internal-service/employee/update/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const updateUserByID = createAsyncThunk('updateUserByID', async (request, { rejectWithValue }) => {
    const userId = request?.user_id
    delete request['user_id']
  const result = await axios
    .post(updateUserByIDURL + userId, request)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log('updateUserByID-Catch', request);
      throw rejectWithValue(error);
    });
  return result;
});

const UpdateUserByIDSlice = createSlice({
  name: 'UpdateUserByID',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate update user by ID state handling*/
      .addCase(updateUserByID.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(updateUserByID.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.data;
      })
      .addCase(updateUserByID.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getUpdateUserByIDState = (state) => state.UpdateUserByID;
export default UpdateUserByIDSlice.reducer;
