import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';
import { configs } from '../../../Util/configs';

const BASE_URL = configs.REACT_APP_BASE_URL_OTHR;
const SystemLogsURL = BASE_URL + '/common-services/systemlog/get-log';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const getSystemLogs = createAsyncThunk(
  'getSystemLogs',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(SystemLogsURL, request)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log('getSystemLogs-Catch', request);
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetSystemLogsSlice = createSlice({
  name: 'GetSystemLogs',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate getSystemLogs state handling*/
      .addCase(getSystemLogs.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getSystemLogs.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.data;
      })
      .addCase(getSystemLogs.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getSystemLogsState = (state) => state.GetSystemLogs;
export default GetSystemLogsSlice.reducer;
