import { notification } from 'antd';

export const openNotification = (message, description, duration, placement = 'topRight', isError) => {
  isError ?notification.error({
    message : 'Error',
    description,
    duration,
    placement,
    key: 'error',
  })
   :
  
  notification.success({
    message,
    description,
    duration,
    placement,
    key: 'success',
  })

};
