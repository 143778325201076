import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

export const DeleteHcPhoto = createApi({
  reducerPath: 'updatePocDetails ',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    deletePhoto: builder.mutation({
      query: (data) => {
        return {
          url: 'common-services/delete-file',
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const { useDeletePhotoMutation } = DeleteHcPhoto;
