import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const GetInternshipOpportunityListURL = '/api/v1/internal-service/io/list';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const getInternshipOpportunityList = createAsyncThunk(
  'getInternMatches',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(GetInternshipOpportunityListURL, request)
      .then((response) => {
        return response?.data?.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetInternshipOpportunityList = createSlice({
  name: 'GetInternMatches',
  initialState,
  reducers: {
    ioStateReset: (state) => {
      state.status = 'idle';
      state.error = null;
      state.response = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getInternshipOpportunityList.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getInternshipOpportunityList.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload;
      })
      .addCase(getInternshipOpportunityList.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getInternshipOpportunityListState = (state) => state.GetInternshipOpportunityList;

export const { ioStateReset } = GetInternshipOpportunityList.actions;
export default GetInternshipOpportunityList.reducer;
