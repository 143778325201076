import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const updateInstituteDetailURL = '/api/v1/internal-service/institutes/update/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const updateInstituteDetail = createAsyncThunk(
  'updateInstituteDetailURL',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(updateInstituteDetailURL, request)
      .then((response) => {
        return response?.data?.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const UpdateInstituteDetailSlice = createSlice({
  name: 'UpdateInstituteDetail',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate career fields status state handling*/
      .addCase(updateInstituteDetail.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(updateInstituteDetail.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload;
      })
      .addCase(updateInstituteDetail.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const UpdateInstituteDetailState = (state) => state.UpdateInstituteDetail;
export default UpdateInstituteDetailSlice.reducer;
