import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const getInternCareerPreferencesURL = '/api/v1/internal-service/intern/get-intern-career-preferences/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
  count: 0,
};
export const getInternCareerPreferences = createAsyncThunk(
  'getInternCareerPreferences',
  async (internId, { rejectWithValue }) => {
    const result = await axios
      .get(getInternCareerPreferencesURL + internId)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetInternCareerPreferencesSlice = createSlice({
  name: 'GetInternCareerPreferences',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate get all user list state handling*/
      .addCase(getInternCareerPreferences.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getInternCareerPreferences.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.data?.payload;
        state.count = action?.payload?.data?.count;
      })
      .addCase(getInternCareerPreferences.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getInternCareerPreferencesState = (state) => state.GetInternCareerPreferences;
export default GetInternCareerPreferencesSlice.reducer;
