import axios from '../../../Axios';

const IEMListURL = '/api/v1/internal-service/group/iem';

const IEMListState = {
  status: 'idle',
  error: null,
  response: null,
};

export const getIEMList = async (withFormat = false) => {
  try {
    const response = await axios.post(IEMListURL);
    if (withFormat) {
      const result = [];
      response.data.data.payload.map((item) => {
        result.push({
          label: item.full_name,
          value: item.employee_id,
        });
      });
      IEMListState.response = result;
    } else {
      IEMListState.response = response.data;
    }
    IEMListState.status = 'Success';
  } catch (error) {
    IEMListState.status = 'Failed';
    IEMListState.error = error;
  }
  return IEMListState;
};
