import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const url = '/api/v1/internal-service/intern/reminder-payment';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const sendReminder = createAsyncThunk('sendReminder', async (request, { rejectWithValue }) => {
  const result = await axios
    .post(url , request)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw rejectWithValue(error);
    });
  return result;
});

const SendReminderSlice = createSlice({
  name: 'SendReminder',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate get by ID state handling*/
      .addCase(sendReminder.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(sendReminder.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.data;
      })
      .addCase(sendReminder.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const sendReminderSlice = (state) => state.SendReminder;
export default SendReminderSlice.reducer;
