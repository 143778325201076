import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';
const url = '/api/v1/internal-service/intern-application/get-profile-feedback';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const getListProvideFeedbackList = createAsyncThunk(
  'getListProvideFeedback',
  async (request) => {
    const result = await axios.post(url, request);
    return result?.data?.data;
  },
);

const GetListProvideFeedback = createSlice({
  name: 'GetListProvideFeedback',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getListProvideFeedbackList.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getListProvideFeedbackList.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload;
      })
      .addCase(getListProvideFeedbackList.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getListProvideFeedbackState = (state) => state.GetListProvideFeedback;
export default GetListProvideFeedback.reducer;
