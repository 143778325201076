import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const updateInterviewSlotURL = '/api/v1/internal-service/intern/edit-interview-suggested-time-slot';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const updateInterviewSlot = createAsyncThunk('updateInterviewSlot', async (request, { rejectWithValue }) => {
  const result = await axios
    .post(updateInterviewSlotURL, request)
    .then((response) => {
      return response?.data?.data;
    })
    .catch((error) => {
      throw rejectWithValue(error);
    });
  return result;
});

const UpdateInterviewSlot = createSlice({
  name: 'UpdateInterviewSlot',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(updateInterviewSlot.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(updateInterviewSlot.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload;
      })
      .addCase(updateInterviewSlot.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const updateInterviewSlotState = (state) => state.UpdateInterviewSlot;
export default UpdateInterviewSlot.reducer;
