import { createApi } from '@reduxjs/toolkit/query/react';
import _map from 'lodash/map';

import { removeFalsyValuesFromObject } from 'Util/utilities';
import { onQueryStartedErrorToast } from 'Util/rtkQueryErrorHandler';
import { objectToQueryString } from '../../../Util/utility';

import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

import { TAG_TYPE } from '../../../Components/Notes/constants/notes.options';

const processUrl = '/api/v1/internal-service/notes/process';
const noteUrl = '/api/v1/internal-service/notes';

const transformProcess = (process) => {
  return _map(process, (process) => {
    return {
      id: process.process_id,
      title: process.process_name,
      is_archived: process.is_archived,
      tag_type: TAG_TYPE['PROCESS'],
    };
  });
};

export const ManageNotesApi = createApi({
  reducerPath: 'manageNotesApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['GetNotesList'],
  endpoints: (builder) => ({
    createNote: builder.mutation({
      query: (payload) => {
        return { url: noteUrl, method: 'POST', body: payload };
      },
      invalidatesTags: (result, error) => (error ? [] : ['GetNotesList']),
    }),

    updateNote: builder.mutation({
      query: (payload) => {
        const noteId = payload?.note_id;
        delete payload['note_id'];

        return { url: `${noteUrl}/${noteId}`, method: 'PATCH', body: payload };
      },
      invalidatesTags: (result, error) => (error ? [] : ['GetNotesList']),
    }),

    listNotes: builder.query({
      query: ({ ...filters }) => {
        const queryString = filters
          ? objectToQueryString(removeFalsyValuesFromObject(filters))
          : '';
        const updatedUrl = queryString ? `${noteUrl}?${queryString}` : noteUrl;
        return { url: updatedUrl, method: 'GET' };
      },
      onQueryStarted: onQueryStartedErrorToast,
      providesTags: ['GetNotesList'],
    }),

    deleteNote: builder.mutation({
      query: (noteId) => {
        return { url: `${noteUrl}/${noteId}`, method: 'DELETE' };
      },
      invalidatesTags: (result, error) => (error ? [] : ['GetNotesList', 'GetFilterList']),
    }),

    getProcess: builder.query({
      query: (body) => {
        const url = body?.searchText ? `${processUrl}?search_text=${body.searchText}` : processUrl;
        return {
          url,
          method: 'GET',
        };
      },
      providesTags: ['process'],
      transformResponse: (response) => transformProcess(response?.payload),
    }),

    addProcess: builder.mutation({
      query: (payload) => {
        return { url: processUrl, method: 'POST', body: payload };
      },
      invalidatesTags: (result, error) => (error ? [] : ['process']),
    }),

    editProcess: builder.mutation({
      query: (payload) => {
        const processId = payload?.process_id;
        delete payload['process_id'];
        return { url: `${processUrl}/${processId}`, method: 'PATCH', body: payload };
      },
      invalidatesTags: (result, error) => (error ? [] : ['process']),
    }),
  }),
});

export const {
  useCreateNoteMutation,
  useAddProcessMutation,
  useEditProcessMutation,
  useGetProcessQuery,
  useDeleteNoteMutation,
  useUpdateNoteMutation,
  useListNotesQuery,
} = ManageNotesApi;
export default ManageNotesApi.reducer;
