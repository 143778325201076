import React, { useState } from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router';
import intl from 'react-intl-universal';
import { navBarItems } from '../Util/NavBar';
import NavBar from '../Components/Navbar/NavBar';
import { isUserAutherisedTo } from '../Redux/Slices/User/Login';
import {
  EXTERNAL_USER_READ,
  EXTERNAL_USER_WRITE,
  HC_DELETE,
  INTERN_DELETE,
} from './permissionConstants';

export default () => {
  const [navLayout, setNavLayout] = useState('vertical');
  const accessPagePermission =
    isUserAutherisedTo(EXTERNAL_USER_READ) ||
    isUserAutherisedTo(EXTERNAL_USER_WRITE) ||
    isUserAutherisedTo(INTERN_DELETE) ||
    isUserAutherisedTo(HC_DELETE);

  return (
    <Layout hasSider style={{ height: '100vh', overflowY: 'hidden', background: '#ffffff' }}>
      <NavBar
        navData={navBarItems(intl, accessPagePermission)}
        navLayout={navLayout}
        setNavLayout={setNavLayout}
      />
      <div
        className='slide-animation'
        style={{ width: navLayout === 'mini' ? 'calc(100% - 88px)' : 'calc(100% - 300px)' }}
      >
        <Outlet />
      </div>
    </Layout>
  );
};
