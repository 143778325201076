import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const getAnnualRevUrl = '/common-services/annual-revenue';
const getIndustryUrl = '/common-services/industry';
const getWorkplaceUrl = '/common-services/workplace-model';
const getFlexibleWorkEnvUrl = '/common-services/flexible-work-environment';
const getLastFullTimeHireUrl = '/common-services/last-full-time-entry-hire';
const getEmployeeCountUrl = '/common-services/number-of-employees/get-list';
const getPoliciesUrl = '/common-services/initiative-and-policies';
const getInformalChatsUrl = '/common-services/informal-chat-session';
const getProductServicesUrl = '/common-services/product-service';
const getOperatingPrinciplesUrl = '/common-services/operating-principle';
const getRemoteVacaniciesUrl = '/common-services/hiring-remote-vacancies';
const getCountryListUrl = '/common-services/location/get-country-list';
const getBusinessListUrl = '/common-services/business-model/get-list';
const getFundingTypeUrl = '/common-services/funding-type';
const getDisablityListURL = '/common-services/disabled/list';

export const CommonListApis = createApi({
  reducerPath: 'commonListApis',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getAnnualRev: builder.query({
      query: () => {
        return { url: getAnnualRevUrl, method: 'GET' };
      },
      transformResponse: (response, meta, arg) => {
        if (!arg?.format) return response;
        const result = [];
        response?.payload?.map((item) => {
          result.push({
            label: item?.annual_revenue_name,
            value: item?.annual_revenue_id,
          });
        });
        return result;
      },
    }),
    getIndustry: builder.query({
      query: () => {
        return { url: getIndustryUrl, method: 'GET' };
      },
      transformResponse: (response, meta, arg) => {
        if (!arg?.format) return response;
        const result = [];
        response?.payload?.results?.map((item) => {
          result.push({
            label: item?.industry_name,
            value: item?.industry_id,
          });
        });
        return result;
      },
    }),
    getWorkplace: builder.query({
      query: () => {
        return { url: getWorkplaceUrl, method: 'GET' };
      },
      transformResponse: (response, meta, arg) => {
        if (!arg?.format) return response;
        const result = [];
        response?.payload?.map((item) => {
          result.push({
            label: item?.workplace_model_name,
            value: item?.workplace_model_id,
          });
        });
        return result;
      },
    }),
    getFlexibleWorkEnv: builder.query({
      query: () => {
        return {
          url: getFlexibleWorkEnvUrl,
          method: 'GET',
        };
      },
      transformResponse: (response, meta, arg) => {
        if (!arg?.format) return response;
        const result = [];
        response?.payload?.map((item) => {
          result.push({
            label: item?.flexible_work_environment_name,
            value: item?.flexible_work_environment_id,
          });
        });
        return result;
      },
    }),
    getLastFullTimeHire: builder.query({
      query: () => {
        return {
          url: getLastFullTimeHireUrl,
          method: 'GET',
        };
      },
      transformResponse: (response, meta, arg) => {
        if (!arg?.format) return response;
        const result = [];
        response?.payload?.map((item) => {
          result.push({
            label: item?.last_full_time_entry_hire_name,
            value: item?.last_full_time_entry_hire_id,
          });
        });
        return result;
      },
    }),
    getEmployeeCount: builder.query({
      query: () => {
        return { url: getEmployeeCountUrl, method: 'GET' };
      },
      transformResponse: (response, meta, arg) => {
        if (!arg?.format) return response;
        const result = [];
        response?.payload?.map((item) => {
          result.push({
            label: item?.number_of_employees,
            value: item?.id,
          });
        });
        return result;
      },
    }),
    getPolicies: builder.query({
      query: () => {
        return { url: getPoliciesUrl, method: 'GET' };
      },
      transformResponse: (response, meta, arg) => {
        if (!arg?.format) return response;
        const result = [];
        response?.payload?.map((item) => {
          result.push({
            label: item?.initiative_and_policies_name,
            value: item?.initiative_and_policies_id,
          });
        });
        return result;
      },
    }),
    getInformalChats: builder.query({
      query: () => {
        return { url: getInformalChatsUrl, method: 'GET' };
      },
      transformResponse: (response, meta, arg) => {
        if (!arg?.format) return response;
        const result = [];
        response?.payload?.map((item) => {
          result.push({
            label: item?.informal_chat_session_name,
            value: item?.informal_chat_session_id,
          });
        });
        return result;
      },
    }),
    getProductServices: builder.query({
      query: () => {
        return { url: getProductServicesUrl, method: 'GET' };
      },
      transformResponse: (response, meta, arg) => {
        if (!arg?.format) return response;
        const result = [];
        response?.payload?.map((item) => {
          result.push({
            label: item?.product_service_name,
            value: item?.product_service_id,
          });
        });
        return result;
      },
    }),

    getOperatingPrinciples: builder.query({
      query: () => {
        return { url: getOperatingPrinciplesUrl, method: 'GET' };
      },
      transformResponse: (response, meta, arg) => {
        if (!arg?.format) return response;
        const result = [];
        response?.payload?.map((item) => {
          result.push({
            label: item?.values_operating_principles_name,
            value: item?.values_operating_principles_id,
          });
        });
        return result;
      },
    }),
    getRemoteVacanicies: builder.query({
      query: () => {
        return { url: getRemoteVacaniciesUrl, method: 'GET' };
      },
      transformResponse: (response, meta, arg) => {
        if (!arg?.format) return response;
        const result = [];
        response?.payload?.map((item) => {
          result.push({
            label: item?.hiring_remote_vacancies_name,
            value: item?.hiring_remote_vacancies_id,
          });
        });
        return result;
      },
    }),
    getCountryList: builder.query({
      query: () => {
        return { url: getCountryListUrl, method: 'GET' };
      },
      transformResponse: (response, meta, arg) => {
        if (!arg?.format) return response;
        const result = [];
        response?.payload?.map((item) => {
          result.push({
            label: item?.name,
            value: item?.id,
          });
        });
        return result;
      },
    }),
    getBusinessList: builder.query({
      query: () => {
        return { url: getBusinessListUrl, method: 'GET' };
      },
      transformResponse: (response, meta, arg) => {
        if (!arg?.format) return response;
        const result = [];
        response?.payload?.map((item) => {
          result.push({
            label: item?.business_model_name,
            value: item?.business_model_id,
          });
        });
        return result;
      },
    }),
    getFundingType: builder.query({
      query: () => {
        return { url: getFundingTypeUrl, method: 'GET' };
      },
      transformResponse: (response, meta, arg) => {
        if (!arg?.format) return response;
        const result = [];
        response?.payload?.results?.map((item) => {
          result.push({
            label: item?.funding_type_name,
            value: item?.funding_type_id,
          });
        });
        return result;
      },
    }),
    getDisablityList: builder.query({
      query: () => {
        return { url: getDisablityListURL, method: 'GET' };
      },
      transformResponse: (response, meta, arg) => {
        if (!arg?.format) return response;
        const result = [];
        response?.payload?.map((item) => {
          result.push({
            label: item?.disabled_name,
            value: item?.disabled_id,
          });
        });
        return result;
      },
    }),
  }),
});

export const {
  useGetAnnualRevQuery,
  useGetEmployeeCountQuery,
  useGetFlexibleWorkEnvQuery,
  useGetIndustryQuery,
  useGetLastFullTimeHireQuery,
  useGetWorkplaceQuery,
  useGetInformalChatsQuery,
  useGetPoliciesQuery,
  useGetProductServicesQuery,
  useGetOperatingPrinciplesQuery,
  useGetRemoteVacaniciesQuery,
  useGetCountryListQuery,
  useGetBusinessListQuery,
  useGetFundingTypeQuery,
  useGetDisablityListQuery,
} = CommonListApis;
export default CommonListApis.reducer;
