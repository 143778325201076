import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const getMatchedInternsDetailURL = '/api/v1/internal-service/intern/get-detail-matched';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const getMatchedInternsDetail = createAsyncThunk('getMatchedInternsDetail', async (request, { rejectWithValue }) => {
  const result = await axios
    .post(getMatchedInternsDetailURL, request)
    .then((response) => {
      return response?.data?.data;
    })
    .catch((error) => {
      throw rejectWithValue(error);
    });
  return result;
});

const GetMatchedInternsDetail = createSlice({
  name: 'GetMatchedInternsDetail',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getMatchedInternsDetail.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getMatchedInternsDetail.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload;
      })
      .addCase(getMatchedInternsDetail.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getMatchedInternsDetailState = (state) => state.GetMatchedInternsDetail;
export default GetMatchedInternsDetail.reducer;
