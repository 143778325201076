import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const url = '/api/v1/internal-service/intern-application/payments-v2';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

// export const getPaymentList = async (request) => {
//   try {
//     const response = await axios.post(url, request);
//     if (response) {
//       initialState.status = 'Success';
//       initialState.response = response?.data?.data;
//     }
//   } catch (error) {
//     initialState.status = 'Failed';
//     initialState.error = error;
//   }
//   return initialState;
// };
export const getPaymentList = createAsyncThunk(
  'getPaymentList',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(url, request)
      .then((response) => {
        return response?.data?.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetPaymentList = createSlice({
  name: 'GetPaymentList',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getPaymentList.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getPaymentList.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload;
      })
      .addCase(getPaymentList.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getPaymentListState = (state) => state.GetPaymentList;
export default GetPaymentList.reducer;
