import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';
import { createSystemLogs } from '../Common/CreateSystemLogs';

const updateBatchStepThreeURL = '/api/v1/internal-service/batch/update/step3';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const updateBatchStepThree = createAsyncThunk(
  'updateBatchStepThree',
  async (request, { rejectWithValue, fulfillWithValue, getState, dispatch }) => {
    try {
      const {
        GetInternshipDates: { response },
      } = getState();
      let internshipDatesWithID = [];
      const info = request?.internshipDates?.map((item) => {
        /* eslint-disable camelcase */
        const batch_internship_date_id = item.id;
        delete item['id'];
        internshipDatesWithID.push({ batch_internship_date_id, ...item });
        return item;
      });
      request.internshipDates = info;

      const batchInfo = {
        feature_type_id: request?.batch_id,
        feature_type: 'BATCH',
        action: 'Update',
        column_name: 'Internship Start',
        from_value: JSON.stringify(response?.results),
        to_value: JSON.stringify(internshipDatesWithID),
      };

      const result = await axios.post(updateBatchStepThreeURL, request);
      // create system logs for batch step Three
      if (result?.data?.data?.isSuccess && internshipDatesWithID?.length > 0) {
        dispatch(createSystemLogs(batchInfo));
      }
      return fulfillWithValue(result?.data?.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const UpdateBatchStepThreeSlice = createSlice({
  name: 'UpdateBatchStepThree',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate update batch state handling*/
      .addCase(updateBatchStepThree.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(updateBatchStepThree.fulfilled, (state, action) => {
        state.status = action.payload.isSuccess ? 'Success' : 'Failed';
        state.response = action?.payload?.payload;
      })
      .addCase(updateBatchStepThree.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getUpdateBatchStepThreeState = (state) => state.UpdateBatchStepThree;
export default UpdateBatchStepThreeSlice.reducer;
