import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';
import { objectToQueryString } from '../../../Util/utility';

const url = '/api/v1/internal-service/offers/list';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
  isLoading: false,
};

export const getOfferList = createAsyncThunk(
  'getOfferList',
  async (request, { rejectWithValue }) => {
    const queryString = objectToQueryString(request);
    const updatedUrl = queryString ? `${url}?${queryString}` : url;
    const result = await axios
      .get(updatedUrl)
      .then(({ data: { data: response } }) => {
        return response;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetOfferList = createSlice({
  name: 'GetOfferList',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getOfferList.pending, (state) => {
        state.status = 'Pending';
        state.isLoading = true;
      })
      .addCase(getOfferList.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload;
        state.isLoading = false;
      })
      .addCase(getOfferList.rejected, (state) => {
        state.status = 'Failed';
        state.isLoading = false;
      });
  },
});

export const getOfferListState = (state) => state.GetOfferList;
export default GetOfferList.reducer;
