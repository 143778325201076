import { forwardRef } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';

import { NavItemProps, NavItemStateProps } from 'Components/Navbar/types';
import { Iconify } from 'Components/Navbar/Iconify';

import { useNavItem } from '../hooks';
import { stateClasses, sharedStyles } from '../styles';

export const NavItem = forwardRef<HTMLButtonElement, NavItemProps>(
  (
    {
      path,
      icon,
      info,
      title,
      caption,
      open,
      depth,
      render,
      active,
      disabled,
      hasChild,
      slotProps,
      externalLink,
      enabledRootRedirect,
      ...other
    },
    ref,
  ) => {
    const navItem = useNavItem({
      path,
      icon,
      info,
      depth,
      render,
      hasChild,
      externalLink,
      enabledRootRedirect,
    });

    return (
      <StyledNavItem
        ref={ref}
        aria-label={title}
        depth={depth}
        active={active}
        disabled={disabled}
        open={open && !active}
        sx={{
          ...slotProps?.sx,
        }}
        className={stateClasses({ open: open && !active, active, disabled })}
        {...navItem.baseProps}
        {...other}
      >
        {icon && (
          <Box component='span' className='mnl__nav__item__icon'>
            {navItem.renderIcon}
          </Box>
        )}

        {title && <Box component='span'>{title}</Box>}

        {caption && (
          <Tooltip title={caption} arrow placement='right'>
            <Iconify icon='eva:info-outline' className='mnl__nav__item__caption' />
          </Tooltip>
        )}

        {info && navItem.subItem && (
          <Box component='span' className='mnl__nav__item__info'>
            {navItem.renderInfo}
          </Box>
        )}

        {hasChild && (
          <Iconify icon='eva:arrow-ios-forward-fill' className='mnl__nav__item__arrow' />
        )}
      </StyledNavItem>
    );
  },
);

// ----------------------------------------------------------------------

const StyledNavItem = styled(ButtonBase, {
  shouldForwardProp: (prop) =>
    prop !== 'active' && prop !== 'open' && prop !== 'disabled' && prop !== 'depth',
})<NavItemStateProps>(({ active, open, disabled, depth, theme }) => {
  const rootItem = depth === 1;

  const subItem = !rootItem;

  const baseStyles = {
    item: {
      width: '100%',
      borderRadius: 'var(--nav-item-radius)',
      color: 'white',
      '&:hover': {
        backgroundColor: '#282F37',
        color: 'white',
      },
    },
    title: {},
    caption: {
      width: 16,
      height: 16,
      color: 'white',
    },
    icon: {
      ...sharedStyles.icon,
      width: '22px',
      height: '22px',
    },
    arrow: { ...sharedStyles.arrow },
    info: { ...sharedStyles.info },
  } as const;

  return {
    /**
     * Root item
     */
    ...(rootItem && {
      ...baseStyles.item,
      textAlign: 'center',
      flexDirection: 'column',
      minHeight: '56px',
      padding: '8px 4px 6px 4px',
      ['& .mnl__nav__item__icon']: {
        ...baseStyles.icon,
        margin: '0px 0px 6px 0px',
      },
      ['& .mnl__nav__item__title']: {
        ...baseStyles.title,
        ...sharedStyles.noWrap,
        lineHeight: '16px',
        fontSize: theme.typography.pxToRem(10),
        fontWeight: active ? theme.typography.fontWeightBold : theme.typography.fontWeightBold,
      },
      ['& .mnl__nav__item__caption']: {
        ...baseStyles.caption,
        top: 11,
        left: 6,
        position: 'absolute',
      },
      ['& .mnl__nav__item__arrow']: {
        ...baseStyles.arrow,
        top: 11,
        right: 6,
        position: 'absolute',
      },
      ['& .mnl__nav__item__info']: { ...baseStyles.info },
      // State
      ...(active && {
        color: 'black',
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: '#282F37',
          color: 'white',
        },
      }),
      ...(open && {
        color: 'black',
        backgroundColor: 'white',
      }),
    }),

    /**
     * Sub item
     */
    ...(subItem && {
      ...baseStyles.item,
      color: theme.palette.text.secondary,
      minHeight: '34px',
      padding: '0px 8px',
      ['& .mnl__nav__item__icon']: {
        ...baseStyles.icon,
        margin: '0px 8px 0px 0px',
      },
      ['& .mnl__nav__item__title']: {
        ...baseStyles.title,
        ...theme.typography.body2,
        fontWeight: active ? theme.typography.fontWeightBold : theme.typography.fontWeightMedium,
        flex: '1 1 auto',
      },
      ['& .mnl__nav__item__caption']: { ...baseStyles.caption },
      ['& .mnl__nav__item__arrow']: {
        ...baseStyles.arrow,
        marginRight: theme.spacing(-0.5),
      },
      ['& .mnl__nav__item__info']: { ...baseStyles.info },
      // State
      ...(active && {
        color: 'white',
        backgroundColor: '#484848',
      }),
      ...(open && {
        color: 'white',
        backgroundColor: 'var(--nav-item-sub-open-bg)',
      }),
    }),

    /* Disabled */
    ...(disabled && sharedStyles.disabled),
  };
});
