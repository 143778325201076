import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const uploadFileURL = '/api/v1/internal-service/s3/upload-file';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const uploadFile = createAsyncThunk('uploadFile', async (request, { rejectWithValue }) => {
  const result = await axios
    .post(uploadFileURL, request)
    .then((response) => {
      return response?.data?.data;
    })
    .catch((error) => {
      throw rejectWithValue(error);
    });
  return result;
});

const UploadFileSlice = createSlice({
  name: 'FileUpload',
  initialState,
  reducers: {
    resetUpload: (state) => {
      state.status = 'idle';
      state.response = null;
    },
  },
  extraReducers(builder) {
    builder
      /* generate file upload state handling*/
      .addCase(uploadFile.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.payload;
      })
      .addCase(uploadFile.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getFileUploadState = (state) => state.FileUpload;
export default UploadFileSlice.reducer;
export const { resetUpload } = UploadFileSlice.actions;
