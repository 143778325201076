import { Tooltip } from '@mui/material';
import { useTruncateText } from 'Hooks/useTruncateText';

interface TruncatedTextProps {
  text: string;
  maxChars?: number;
  className?: string;
}

function TruncatedTextWithTooltip({
  text,
  maxChars = 30,
  className = '',
}: TruncatedTextProps): JSX.Element {
  const truncatedText = useTruncateText(text, maxChars);
  const isTruncated = truncatedText !== text;
  return (
    <>
      {isTruncated ? (
        <Tooltip placement={'top'} title={<p className='fs-14'>{text}</p>} arrow>
          <span className={className}>{truncatedText}</span>
        </Tooltip>
      ) : (
        <span className={className}>{text}</span>
      )}
    </>
  );
}

export default TruncatedTextWithTooltip;
