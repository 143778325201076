import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const url = '/api/v1/internal-service/employee/me';
const updateProfilePicUrl = '/api/v1/internal-service/employee/profile-picture';

export const GetProfileApi = createApi({
  reducerPath: 'GetProfileApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getProfile: builder.query({
      query: () => {
        return { url, method: 'GET' };
      },
    }),
    updateProfilePic: builder.mutation({
      query: (body) => {
        return { url: updateProfilePicUrl, method: 'PUT', body };
      },
    }),
    deleteProfilePic: builder.mutation({
      query: () => {
        const url = updateProfilePicUrl;
        return { url, method: 'DELETE' };
      },
    }),
  }),
});

export const { useGetProfileQuery, useUpdateProfilePicMutation, useDeleteProfilePicMutation } =
  GetProfileApi;

export default GetProfileApi.reducer;
