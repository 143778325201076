import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';
import _map from 'lodash/map';

const getInternInternshipsUrl = '/api/v1/internal-service/internship';
const pauseInternshipDatesUrl = '/api/v1/internal-service/internship/pause';
const completeInternships = '/api/v1/internal-service/internship/complete';
const changeCompanyUrl = '/api/v1/internal-service/internship/company/change';
const changeCompanyReasonUrl = '/api/v1/internal-service/internship/company/company-change-reasons';

export function convertToURLSearchParams(obj) {
  const params = new URLSearchParams();

  function convertValue(value, key) {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        params.append(`${key}[]`, item);
      });
    } else {
      params.append(key, value);
    }
  }

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];

      if (value !== null && value !== undefined) {
        if (Array.isArray(value)) {
          convertValue(value, key);
        } else {
          convertValue(value, key);
        }
      }
    }
  }

  return params?.toString();
}

const transformReasons = (reasons) => {
  return _map(reasons, (reason) => {
    return {
      id: reason.change_company_reason_id,
      title: reason.change_company_reason,
      is_archived: reason.is_archived,
    };
  });
};

export const ManageInternshipsApi = createApi({
  reducerPath: 'manageInternshipsApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['internshipsList', 'internshipById', 'companyChangeReasons'],
  endpoints: (builder) => ({
    getInternInternships: builder.query({
      query: (searchParams) => {
        const searchParamCopy = Object.assign({}, searchParams);
        const defaultFilters = {
          page_number: 1,
          per_page: 100,
        };

        if (!searchParamCopy?.page_number) {
          searchParamCopy.page_number = defaultFilters.page_number;
        }
        if (!searchParamCopy?.per_page) {
          searchParamCopy.per_page = defaultFilters.per_page;
        }

        let url = getInternInternshipsUrl;
        if (Object.keys(searchParamCopy)?.length > 0) {
          url = `${getInternInternshipsUrl}?${convertToURLSearchParams(searchParamCopy)}`;
        } else {
          url = `${getInternInternshipsUrl}?${convertToURLSearchParams(defaultFilters)}`;
        }
        return {
          url,
          method: 'GET',
          body: null,
          headers: {
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
          },
        };
      },
      providesTags: ['internshipsList'],
    }),
    getInternInternshipDetailsById: builder.query({
      query: (id) => {
        let url = `${getInternInternshipsUrl}/${id}`;
        return {
          url,
          method: 'GET',
          body: null,
        };
      },
      providesTags: ['internshipById'],
    }),

    pauseInternshipDates: builder.mutation({
      query: (payload) => {
        return { url: pauseInternshipDatesUrl, method: 'POST', body: payload };
      },
      invalidatesTags: (result, error) => (error ? [] : ['internshipsList', 'internshipById']),
    }),
    completeInternships: builder.mutation({
      query: (payload) => {
        return { url: completeInternships, method: 'POST', body: payload };
      },
      invalidatesTags: (result, error) => (error ? [] : ['internshipsList', 'internshipById']),
    }),
    changeCompany: builder.mutation({
      query: (payload) => {
        return { url: changeCompanyUrl, method: 'POST', body: payload };
      },
      invalidatesTags: (result, error) => (error ? [] : ['internshipsList', 'internshipById']),
    }),

    getChangeCompanyReasons: builder.query({
      query: () => {
        return {
          url: changeCompanyReasonUrl,
          method: 'GET',
        };
      },
      providesTags: ['companyChangeReasons'],
      transformResponse: (response) => transformReasons(response?.payload),
    }),

    addChangeCompanyReason: builder.mutation({
      query: (payload) => {
        return { url: changeCompanyReasonUrl, method: 'POST', body: payload };
      },
      invalidatesTags: (result, error) => (error ? [] : ['companyChangeReasons']),
    }),

    editChangeCompanyReason: builder.mutation({
      query: (payload) => {
        const reasonId = payload?.reason_id;
        delete payload['reason_id'];
        return { url: `${changeCompanyReasonUrl}/${reasonId}`, method: 'PATCH', body: payload };
      },
      invalidatesTags: (result, error) => (error ? [] : ['companyChangeReasons']),
    }),
  }),
});

export const {
  useGetInternInternshipsQuery,
  useGetInternInternshipDetailsByIdQuery,
  usePauseInternshipDatesMutation,
  useCompleteInternshipsMutation,
  useChangeCompanyMutation,
  useGetChangeCompanyReasonsQuery,
  useAddChangeCompanyReasonMutation,
  useEditChangeCompanyReasonMutation,
  usePrefetch,
} = ManageInternshipsApi;
export default ManageInternshipsApi.reducer;
