import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const deleteFileURL = 'api/v1/internal-service/s3/delete-object';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const deleteFile = createAsyncThunk('deleteFile', async (request, { rejectWithValue }) => {
  const result = await axios
    .post(deleteFileURL, request)
    .then((response) => {
      return response?.data?.data;
    })
    .catch((error) => {
      throw rejectWithValue(error);
    });
  return result;
});

const DeleteFileSlice = createSlice({
  name: 'DeleteFile',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate file delete state handling*/
      .addCase(deleteFile.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(deleteFile.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response =  action?.payload;
      })
      .addCase(deleteFile.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getFileDeleteState = (state) => state.DeleteFile;
export default DeleteFileSlice.reducer;
