import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const approveQuestionAndAnswerURL =
  '/api/v1/internal-service/intern-application/approve-question-answer';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
  count: 0,
};
export const approveQuestionAndAnswer = createAsyncThunk(
  'approveQuestionAndAnswer',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(approveQuestionAndAnswerURL, request)
      .then((response) => {
        return response?.data?.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const ApproveQuestionAndAnswerSlice = createSlice({
  name: 'ApproveQuestionAndAnswer',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate state handling*/
      .addCase(approveQuestionAndAnswer.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(approveQuestionAndAnswer.fulfilled, (state, action) => {
        state.status = action.payload.isSuccess ? 'Success' : 'Failed';
        state.response = action?.payload?.payload;
      })
      .addCase(approveQuestionAndAnswer.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const approveQuestionAndAnswerState = (state) => state.ApproveQuestionAndAnswer;
export default ApproveQuestionAndAnswerSlice.reducer;
