import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';
import { getParamObjectModifier } from '../../../Util/utility';

const getFullInternListURL = '/api/v1/internal-service/intern/old';

export const GetFullInternListApi = createApi({
  reducerPath: 'getFullInternListApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getFullInternListApi: builder.query({
      query: (data) => {
        const modifiedParam = getParamObjectModifier(data);
        return { url: getFullInternListURL, method: 'GET', body: modifiedParam };
      },
    }),
  }),
});

export const { useGetFullInternListApiQuery } = GetFullInternListApi;

export default GetFullInternListApi.reducer;
