import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';


const approveInternApplicationURL =
  '/api/v1/internal-service/intern-application/approve-video-and-ready-matching';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
  isLoading: false,
};

export const approveInternApplication = createAsyncThunk(
  'approveInternApplication',
  async (request, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await axios.post(approveInternApplicationURL, request);

      if (result?.data?.data?.isSuccess) {
        return fulfillWithValue(result?.data?.data);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const approveInternApplicationSlice = createSlice({
  name: 'approveInternApplication',
  initialState,
  reducers: {
    approveInternApplicationStateReset: (state) => {
      state.status = 'idle';
      state.error = null;
      state.response = null;
      state.isLoading = false;
    },
  },
  extraReducers: {
    [approveInternApplication.pending]: (state) => {
      state.status = 'Pending';
      state.isLoading = true;
    },
    [approveInternApplication.fulfilled]: (state, action) => {
      state.status = 'Success';
      state.response = action?.payload;
      state.isLoading = false;
    },
    [approveInternApplication.rejected]: (state, error) => {
      state.status = 'Failed';
      state.error = error;
      state.isLoading = false;
    },
  },
});

export const approveInternApplicationState = (state) => state.ApproveInternApplication;
export const { approveInternApplicationStateReset } = approveInternApplicationSlice.actions;
export default approveInternApplicationSlice.reducer;
