import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';
const url = 'api/v1/internal-service/host-company/interview/';

export const RevertConfirmationApi = createApi({
  reducerPath: 'revertConfirmationApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    revertConfirmation: builder.mutation({
      query: (payload) => {
        return {
          url: url + payload.id + '/revert',
          method: 'PATCH',
        };
      },
    }),
  }),
});

export const { useRevertConfirmationMutation } = RevertConfirmationApi;
export default RevertConfirmationApi.reducer;
