import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const updateGlobalSettingURL = 'api/v1/internal-service/global-setting/update/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const updateGlobalSetting = createAsyncThunk(
  'updateGlobalSettingURL',
  async (request, { rejectWithValue }) => {
    const url = updateGlobalSettingURL + request.signup_signin_config_id;
    delete request.signup_signin_config_id;
    const result = await axios
      .post(url, request)
      .then((response) => {
        return response?.data?.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const UpdateGlobalSettingSlice = createSlice({
  name: 'UpdateGlobalSetting',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate career fields status state handling*/
      .addCase(updateGlobalSetting.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(updateGlobalSetting.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload;
      })
      .addCase(updateGlobalSetting.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const UpdateGlobalSettingSliceState = (state) => state.UpdateGlobalSetting;
export default UpdateGlobalSettingSlice.reducer;
