import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import axios from '../../../Axios';
import { createSystemLogs } from '../Common/CreateSystemLogs';

const createPartnerURL = '/api/v1/internal-service/partner/create';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
  message: null,
};

export const createPartner = createAsyncThunk(
  'createPartner',
  async (request, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const result = await axios.post(createPartnerURL, request);
      const partnerInfo = {
        feature_type_id: result?.data?.data?.payload?.partner_id,
        feature_type: 'PARTNER',
        action: 'Create',
        column_name: 'Partner',
        from_value: ' ',
        to_value: `${result?.data?.data?.payload?.partner_id}`,
      };
      if (result?.data?.data?.isSuccess) {
        dispatch(createSystemLogs(partnerInfo));
      }
      if(!result?.data?.data?.isSuccess){
        throw rejectWithValue(result)
       }
      return fulfillWithValue(result?.data?.data);
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);

const CreatePartnerSlice = createSlice({
  name: 'CreatePartner',
  initialState,
  extraReducers: {
    [createPartner.pending]: (state) => {
      state.status = 'Pending';
      state.error = null;
    },
    [createPartner.fulfilled]: (state, action) => {
      state.status = 'Success';
      state.response = action?.payload?.payload;
      const message = action?.payload?.message;
      if (message === 'Partner Slug exist' || message === 'Partner Name exist') {
        notification.error({ message, duration: 3 });
      } else if (!action?.payload?.payload?.partner_id)
        notification.error({ message: 'Somthing went wrong!', duration: 2 });
    },
    [createPartner.rejected]: (state, action) => {
      state.status = 'Failed';
      state.error = action?.payload;
      state.response = action?.payload?.payload;
    },
  },
});

export const getCreatePartnerState = (state) => state.CreatePartner;
export default CreatePartnerSlice.reducer;
