import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const getManualRecommendedProfilesPastURL =
  '/api/v1/internal-service/recommendation/get-past';

export const GetManualRecommendedProfilesPastApi = createApi({
  reducerPath: 'GetManualRecommendedProfilesPastApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getManualRecommendedProfilesPast : builder.mutation({
      query: (data) => ({ url: getManualRecommendedProfilesPastURL, method: 'POST' , body : data }),
    }),
  }),
});

export const { useGetManualRecommendedProfilesPastMutation } = GetManualRecommendedProfilesPastApi;
export default GetManualRecommendedProfilesPastApi.reducer;