import { notification } from 'antd';
import axios from 'axios';
import { configs } from '../Util/configs';
import { datadogLogs } from '@datadog/browser-logs';

const BASE_URL = configs.REACT_APP_BASE_URL;
const AUTH_URL = '/api/v1/internal-service/auth/google/login';
const AUTH_URL_2FA = '/api/v1/internal-service/auth/2fa/validate';
const ENABLE_2FA = '/api/v1/internal-service/auth/2fa/enable';
const ENABLE_2FA_VERIFY = '/api/v1/internal-service/auth/2fa/verify';
const NORMAL_LOGIN_FLOW_URL = '/api/v1/internal-service/auth/sign-in';

const axiosObject = axios.create({
  baseURL: BASE_URL,
  withCredentials: true, // adding this for external access requests, please do not remove. [change by Vishwanath A. Telsang]
});

const JWT_EXPIRED = 'jwt expired';
const UNAUTHORIZED = 'Unauthorized';
const FORBIDDEN = 401;

const logTrigger = (message, jsonAttributes, status, error = null) => {
  datadogLogs.logger.log(message, jsonAttributes, status, error);
};

export function jwtRequestInterceptor() {
  axiosObject.interceptors.request.use((request) => {
    // add auth header with jwt if account is logged in and request is to the api url
    if (
      localStorage.getItem('JWT_TOKEN') &&
      (!request.url.includes(AUTH_URL) || !request.url.includes(NORMAL_LOGIN_FLOW_URL))
    ) {
      request.headers['x-vi-internal-authorization'] = localStorage.getItem('JWT_TOKEN');
    } else if (
      localStorage.getItem('token2fa') &&
      (request.url.includes(ENABLE_2FA) || request.url.includes(ENABLE_2FA_VERIFY))
    ) {
      request.headers['x-vi-internal-authorization'] = localStorage.getItem('token2fa');
    }
    return request;
  });
}

export function jwtResponseInterceptor() {
  axiosObject.interceptors.response.use(
    (response) => {
      if (
        ((response.config.url.includes(AUTH_URL) ||
          response.config.url.includes(NORMAL_LOGIN_FLOW_URL)) &&
          response.data?.data?.payload?.is_2fa_required === 0) ||
        (response.config.url.includes(AUTH_URL_2FA) &&
          response.data?.data?.payload?.is_2fa_enabled === 1)
      ) {
        localStorage.setItem('JWT_TOKEN', response.data?.data?.payload?.token);
        localStorage.setItem('EMPLOYEE_ID', response.data?.data?.payload?.employee_id);
        localStorage.setItem('USER_GROUPS', JSON.stringify(response.data?.data?.payload?.groups));
      }
      return response;
    },
    (error) => {
      // special case if the user is login and we push the code for 2FA
      const errCode = error?.response?.data?.status;
      const errMsg = error?.response?.data?.data?.message;

      logTrigger(
        errMsg,
        { errorConfig: error?.response?.data, errorCode: errCode },
        'error',
        error,
      );

      if (errCode === 403 && errMsg === 'Please set up 2FA authentication before proceeding.') {
        notification.error({
          message: 'Error',
          description: 'Please refresh and login again',
          duration: 5,
          placement: 'topRight',
        });

        localStorage.clear();
      }

      if (
        error?.response?.data?.data?.message === UNAUTHORIZED ||
        error?.response?.status === FORBIDDEN ||
        error?.response?.data?.data?.message === JWT_EXPIRED
      ) {
        localStorage.removeItem('JWT_TOKEN');
        localStorage.removeItem('USER_GROUPS');
        localStorage.removeItem('EMPLOYEE_ID');
        localStorage.removeItem('token2fa');

        // Added timeout so that user can see error(if any) before redirection
        setTimeout(() => {
          window.location.replace('/sign-in');
        }, 1000);
      }
      return error?.response || { error: error?.message };
    },
  );
}

export default axiosObject;
