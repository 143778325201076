import React from 'react';
import { Outlet } from 'react-router';
import { Layout } from 'antd';

export default () => {
  return (
    <Layout
      hasSider
      style={{
        minHeight: '100vh',
        minWidth: '100vw',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#ffffff',
      }}
    >
      <Outlet />
    </Layout>
  );
};
