import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const GetSocialMediaDetailsURL = '/api/v1/internal-service/host-company/get-social-media-details';

export const GetSocialMediaDetails = createApi({
  reducerPath: 'getSocialMediaDetails',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getSocialMediaDetails: builder.query({
      query: (request) => ({ url: GetSocialMediaDetailsURL, method: 'POST', body: request }),
    }),
  }),
});

export const { useGetSocialMediaDetailsQuery } = GetSocialMediaDetails;
