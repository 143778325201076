import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const getInstituteDetailURL = '/api/v1/internal-service/institutes/get/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const getInstituteDetail = createAsyncThunk(
  'getInstituteDetailURL',
  async (instituteId, { rejectWithValue }) => {
    const url = getInstituteDetailURL + instituteId;
    const result = await axios
      .get(url)
      .then((response) => {
        return response?.data?.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetInstituteDetailSlice = createSlice({
  name: 'GetInstituteDetail',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate career fields status state handling*/
      .addCase(getInstituteDetail.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getInstituteDetail.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload;
      })
      .addCase(getInstituteDetail.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const GetInstituteDetailState = (state) => state.GetInstituteDetail;
export default GetInstituteDetailSlice.reducer;
