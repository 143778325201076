import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

export const PostCustomMailApi = createApi({
  reducerPath: 'postCustomMailApi',
  baseQuery: axiosBaseQuery(),

  endpoints: (builder) => ({
    customMail: builder.mutation({
      query: (request) => ({
        url: '/api/v1/internal-service/email/send',
        method: 'POST',
        body: request,
      }),
    }),
  }),
});

export const { useCustomMailMutation } = PostCustomMailApi;
export default PostCustomMailApi.reducer;
