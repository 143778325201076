import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const GetHostCompanyDetailURL = '/api/v1/internal-service/host-company/get-details/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
  isLoading: false,
};
export const getHostCompanyDetail = createAsyncThunk(
  'getHostCompanyDetail',
  async (profileId = '', { rejectWithValue }) => {
    const url = GetHostCompanyDetailURL + profileId;
    const result = await axios
      .get(url)
      .then((response) => {
        return response?.data?.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetHostCompanyDetail = createSlice({
  name: 'GetHostCompanyDetail',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getHostCompanyDetail.pending, (state) => {
        state.status = 'Pending';
        state.isLoading = true;
      })
      .addCase(getHostCompanyDetail.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload;
        state.isLoading = false;
      })
      .addCase(getHostCompanyDetail.rejected, (state) => {
        state.status = 'Failed';
        state.isLoading = false;
      });
  },
});

export const getHostCompanyDetailState = (state) => state.GetHostCompanyDetail;
export default GetHostCompanyDetail.reducer;
