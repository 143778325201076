import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';
import { getOfferById } from './GetOfferById';

const url = '/api/v1/internal-service/offers/update-offer-status';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const updateStatus = createAsyncThunk(
  'updateStatus',
  async (request, { rejectWithValue, dispatch }) => {
    const result = await axios
      .post(url, request)
      .then((response) => {
        if (response?.data?.status === 200) {
          dispatch(getOfferById(request?.offer_id));
        }
        return response?.data?.data?.payload;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const UpdateStatus = createSlice({
  name: 'UpdateStatus',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(updateStatus.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(updateStatus.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload;
      })
      .addCase(updateStatus.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const updateStatusState = (state) => state.UpdateStatus;
export default UpdateStatus.reducer;
