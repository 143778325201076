import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const updateInternVideoFeedbackURL =
  '/api/v1/internal-service/intern-application/question-video-feedback';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
  isLoading: false,
};

export const updateInternVideoFeedback = createAsyncThunk(
  'updateInternVideoFeedback',
  async (request, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await axios.post(updateInternVideoFeedbackURL, request);

      if (result?.data?.data?.isSuccess) {
        return fulfillWithValue(result?.data?.data);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const updateInternVideoFeedbackSlice = createSlice({
  name: 'updateInternVideoFeedback',
  initialState,
  reducers: {
    updateInternVideoFeedbackStateReset: (state) => {
      state.status = 'idle';
      state.error = null;
      state.response = null;
      state.isLoading = false;
    },
  },
  extraReducers: {
    [updateInternVideoFeedback.pending]: (state) => {
      state.status = 'Pending';
      state.isLoading = true;
    },
    [updateInternVideoFeedback.fulfilled]: (state, action) => {
      state.status = 'Success';
      state.response = action?.payload;
      state.isLoading = false;
    },
    [updateInternVideoFeedback.rejected]: (state, error) => {
      state.status = 'Failed';
      state.error = error;
      state.isLoading = false;
    },
  },
});

export const updateInternVideoFeedbackState = (state) => state.UpdateInternVideoFeedback;
export const { updateInternVideoFeedbackStateReset } = updateInternVideoFeedbackSlice.actions;
export default updateInternVideoFeedbackSlice.reducer;
