import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const getInternListURL = '/api/v1/internal-service/intern/list';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
  count: 0,
};
export const getInternList = createAsyncThunk(
  'getInternList',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(getInternListURL, request)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetInternListSlice = createSlice({
  name: 'InternList',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate get all user list state handling*/
      .addCase(getInternList.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getInternList.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.data?.payload;
        state.count = action?.payload?.data?.count;
      })
      .addCase(getInternList.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getInternListState = (state) => state.InternList;
export default GetInternListSlice.reducer;
