import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const URL = '/api/v1/internal-service/pam/application';

export const GetApplicationListPAMApi = createApi({
  reducerPath: 'getApplicationListApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    applicationPAM: builder.query({
      query: (data) => ({ url: URL, method: 'GET', body: data }),
    }),
  }),
});

export const { useApplicationPAMQuery } = GetApplicationListPAMApi;

export default GetApplicationListPAMApi.reducer;
