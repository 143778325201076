import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';
import { createMassSystemLogs } from '../Common/CreateMassSystemLogs';

const updateInternBatchURL = '/api/v1/internal-service/intern/update-batch';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const updateInternBatch = createAsyncThunk(
  'updateInternBatch',
  async (request, { rejectWithValue, dispatch, fulfillWithValue, getState }) => {
    try {
      // grab state from store
      const { BatchWiseInternList } = getState();
      const updateInternBatchList = request?.intern_ids?.map((item) => {
        const internDetails = BatchWiseInternList?.response?.find(
          (intern) => intern?.intern_id === item,
        );
        return {
          feature_type_id: item,
          feature_type: 'INTERN',
          action: 'Update',
          column_name: 'Change Intern Batch',
          from_value: `${internDetails?.batch?.batch_code} - ${internDetails?.batch?.batch_name}`,
          to_value: `${request?.info?.batch_code} - ${request?.info?.batchName}`,
        };
      });
      delete request['info'];
      const result = await axios.post(updateInternBatchURL, request);
      if (result?.data?.data?.isSuccess) {
        dispatch(createMassSystemLogs(updateInternBatchList));
      }
      return fulfillWithValue(result?.data);
    } catch (error) {
      throw rejectWithValue(error);
    }
  },
);

const UpdateInternBatchSlice = createSlice({
  name: 'UpdateInternBatch',
  initialState,
  reducers: {
    setVerifyStatus: (state) => {
      state.status = 'idle';
      state.response = null;
    },
  },
  extraReducers(builder) {
    builder
      /* generate updateInternBatch state handling*/
      .addCase(updateInternBatch.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(updateInternBatch.fulfilled, (state, action) => {
        state.status = action?.payload?.data?.isSuccess ? 'Success' : 'Failed';
        state.response = action?.payload?.data?.payload;
        state.errorMessage = action?.payload?.data?.message;
      })
      .addCase(updateInternBatch.rejected, (state, action) => {
        state.status = 'Failed';
        state.response = action?.payload?.response
          ? action?.payload?.response
          : { data: action.payload?.payload };
        state.errorMessage = action?.payload?.data?.message;
      });
  },
});

export const getUpdateInternBatchState = (state) => state.UpdateInternBatch;
export default UpdateInternBatchSlice.reducer;
export const { setVerifyStatus } = UpdateInternBatchSlice.actions;
