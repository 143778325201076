import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const GetCompanyListPAMWiseURL = '/api/v1/internal-service/host-company/list-cam-wise';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const getCompanyListPAMWise = createAsyncThunk('getCompanyListPAMWise', async (request, { rejectWithValue }) => {
  const result = await axios
    .post(GetCompanyListPAMWiseURL, request)
    .then((response) => {
      return response?.data?.data;
    })
    .catch((error) => {
      throw rejectWithValue(error);
    });
  return result;
});

const GetCompanyListPAMWise = createSlice({
  name: 'GetCompanyListPAMWise',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCompanyListPAMWise.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getCompanyListPAMWise.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload;
      })
      .addCase(getCompanyListPAMWise.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getCompanyListPAMWiseState = (state) => state.GetCompanyListPAMWise;
export default GetCompanyListPAMWise.reducer;
