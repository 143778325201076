import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const getUserByIDURL = '/api/v1/internal-service/employee/get/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const getUserByID = createAsyncThunk('getUserByID', async (request, { rejectWithValue }) => {
  const result = await axios
    .get(getUserByIDURL + request?.user_id)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log('getUserByID-Catch', request);
      throw rejectWithValue(error);
    });
  return result;
});

const GetUserByIDSlice = createSlice({
  name: 'UserByID',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate get by ID state handling*/
      .addCase(getUserByID.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getUserByID.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.data;
      })
      .addCase(getUserByID.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getUserByIDState = (state) => state.UserByID;
export default GetUserByIDSlice.reducer;
