import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const getApprovedHostCompanyToRecommendURL =
  '/api/v1/internal-service/host-company/approved/list';

export const GetApprovedHostCompanyToRecommendApi = createApi({
  reducerPath: 'GetApprovedHostCompanyToRecommendApi',
  baseQuery: axiosBaseQuery(
    getApprovedHostCompanyToRecommendURL,
    'POST'
  ),
  endpoints: (builder) => ({
    getApprovedHostCompanyToRecommend : builder.mutation({
      query: (data) => ({ url: getApprovedHostCompanyToRecommendURL, method: 'POST' , body : data }),
    }),
  }),
});

export const { useGetApprovedHostCompanyToRecommendMutation } = GetApprovedHostCompanyToRecommendApi;
export default GetApprovedHostCompanyToRecommendApi.reducer;