import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const URL = '/api/v1/internal-service/intern/batch/';

export const GetBatchDetailApi = createApi({
  reducerPath: 'GetBatchDetailApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    batchDetail: builder.query({
      query: (id) => ({ url: URL + id, method: 'GET' }),
    }),
    batchDetails: builder.query({
      query: (id) => ({ url: URL + id, method: 'GET' }),
    }),
    signUp: builder.mutation({
      query: (id) => ({
        url: 'api/v1/internal-service/batch/' + id + '/signup-settings',
        method: 'GET',
      }),
    }),
    getBatchDetail: builder.query({
      query: (id) => ({ url: URL + id, method: 'GET' }),
    }),
  }),
});

export const {
  useBatchDetailQuery,
  useBatchDetailsQuery,
  useSignUpMutation,
  useGetBatchDetailQuery,
} = GetBatchDetailApi;
export default GetBatchDetailApi.reducer;
