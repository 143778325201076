import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const url = '/api/v1/internal-service/intern/b2b';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
  count: 0,
};
export const getB2bInternList = createAsyncThunk(
  'getB2bInternList',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(url, request)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetB2bInternListSlice = createSlice({
  name: 'GetB2bInternList',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate get all user list state handling*/
      .addCase(getB2bInternList.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getB2bInternList.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.data?.payload;
        state.count = action?.payload?.data?.count;
      })
      .addCase(getB2bInternList.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getB2bInternListState = (state) => state.GetB2bInternList;
export default GetB2bInternListSlice.reducer;
