import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const getUserListURL = '/api/v1/internal-service/employee/list';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
  count: 0,
};
export const getUserList = createAsyncThunk('getUserList', async (request, { rejectWithValue }) => {
  const result = await axios
    .post(getUserListURL, request)
    .then((response) => {
      return response?.data?.data;
    })
    .catch((error) => {
      throw rejectWithValue(error);
    });
  return result;
});

const GetUserListSlice = createSlice({
  name: 'UserList',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate get all user list state handling*/
      .addCase(getUserList.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getUserList.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.payload;
        state.count = action?.payload?.count;
      })
      .addCase(getUserList.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getUserListState = (state) => state.UserList;
export default GetUserListSlice.reducer;
