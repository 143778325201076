import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

export const ManageVideoApplicationApi = createApi({
  reducerPath: 'manageVideoApplicationApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['applicationDetails'],

  endpoints: (builder) => ({
    getVideoQuestion: builder.query({
      query: (internId) => {
        const url = '/api/v1/internal-service/intern/video' + `/${internId}`;
        return { url, method: 'GET', body: null };
      },
      providesTags: ['applicationDetails'],
    }),
    rateVideo: builder.mutation({
      query: (body) => {
        const url = '/api/v1/internal-service/intern/video/rating';
        return { url, method: 'POST', body };
      },
      invalidatesTags: (result, error) => (error ? [] : ['applicationDetails']),
    }),
    provideFeedback: builder.mutation({
      query: (body) => {
        const url = '/api/v1/internal-service/intern/video/feedback';
        return { url, method: 'POST', body };
      },
      invalidatesTags: (result, error) => (error ? [] : ['applicationDetails']),
    }),
    shareFeedback: builder.mutation({
      query: (body) => {
        const url = '/api/v1/internal-service/intern/video/submit-feedback';
        return { url, method: 'POST', body };
      },
      invalidatesTags: (result, error) => (error ? [] : ['applicationDetails']),
    }),
    getVideoFeedbackReasons: builder.query({
      query: () => {
        const url = '/common-services/feedback-reasons/video';
        return { url, method: 'GET', body: null };
      },
    }),
  }),
});

export const {
  useGetVideoQuestionQuery,
  useRateVideoMutation,
  useProvideFeedbackMutation,
  useShareFeedbackMutation,
  useGetVideoFeedbackReasonsQuery,
} = ManageVideoApplicationApi;
export default ManageVideoApplicationApi.reducer;
