import { showSnackbar } from 'Redux/Slices/Common/SnackbarSlice';

export const onQueryStartedErrorToast = async (args, { dispatch, queryFulfilled }) => {
  try {
    await queryFulfilled;
  } catch (error) {
    dispatch(
      showSnackbar({
        severity: 'error',
        alertTitle: 'Error',
        message: error.error?.message || error.error?.data || '',
      }),
    );
  }
};
