import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../Axios/AxiosInstance';

import { objectToQueryString } from '../../../../Util/utility';

const hostCompanyTableListURL = '/api/v1/internal-service/host-company/get-company-detail';
const GetHostCompanyDetailURL = '/api/v1/internal-service/host-company/get-details/';
const UpdateHostCompanyStatusURL = '/api/v1/internal-service/host-company/toggle-status';
export const HostCompanyTableApi = createApi({
  reducerPath: 'hostCompanyApiTableApi',
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getCompanyDetails: builder.query({
      query: (request) => {
        const queryString = objectToQueryString(request);
        const updatedUrl = queryString
          ? `${hostCompanyTableListURL}?${queryString}`
          : hostCompanyTableListURL;
        return {
          url: updatedUrl,
          method: 'GET',
        };
      },
    }),
    getHostCompanyDetails: builder.query({
      query: (request) => {
        return {
          url: `${GetHostCompanyDetailURL}${request.hc_id}`,
          method: 'GET',
        };
      },
    }),
    updateCompanyStatus: builder.mutation({
      query: (request) => {
        return {
          url: UpdateHostCompanyStatusURL,
          method: 'POST',
          data: request,
        };
      },
    }),
  }),
});

export const {
  useGetCompanyDetailsQuery,
  usePrefetch,
  useGetHostCompanyDetailsQuery,
  useUpdateCompanyStatusMutation,
} = HostCompanyTableApi;

export default HostCompanyTableApi.reducer;
