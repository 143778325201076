import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const getInternByIDURL = '/api/v1/internal-service/intern/get-intern/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const getInternByID = createAsyncThunk(
  'getInternByID',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .get(getInternByIDURL + request.intern_id)
      .then((response) => {
        return response?.data?.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetInternByIdSlice = createSlice({
  name: 'InternByID',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      /* generate get intern by ID state handling*/
      .addCase(getInternByID.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getInternByID.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.payload;
      })
      .addCase(getInternByID.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getInternByIDState = (state) => state.InternByID;
export const { reset } = GetInternByIdSlice.actions;
export default GetInternByIdSlice.reducer;
