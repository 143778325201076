import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const googleLoginUrl = '/api/v1/internal-service/auth/google/login';

export const AuthApi = createApi({
  reducerPath: 'authApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    googleLogin: builder.mutation({
      query: (payload) => {
        return { url: googleLoginUrl, method: 'POST', body: payload };
      },
    }),
  }),
});

export const { useGoogleLoginMutation } = AuthApi;
export default AuthApi.reducer;
